import { useCallback, useEffect, useState } from 'react'
import useSWR, { mutate } from 'swr'
import { PaginationInfo } from 'types/common'
import { generateDefaultQueryParams } from 'types/default-params'
import { CreateUserDTO, UpdateUserDTO, User, UserQueryParams, UserSearch, UserWithPlanInfo } from 'types/user'
import { UserProfile } from 'types/user-profile'
import { deleteWithTokenWithStatus, fetchWithToken, postWithToken, putWithToken } from './http'

export function useFetchOrganizationUsers(token: string, orgId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR([`organizations/${orgId}/users`, token, params], ([url, token, params]) =>
    fetchWithToken(url, token, params)
  )

  return {
    users: data?.users as User[],
    isLoading,
    isError: error,
  }
}

export function useFetchUser(
  token: string,
  userId: string | null,
  params: [[string, string]] | null,
  shouldFetch: boolean = true
) {
  const [isLoading, setIsLoading] = useState(false)
  const { data, error, mutate } = useSWR(
    shouldFetch && userId ? [`users/${userId}`, token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, params),
    {
      suspense: false,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  )

  useEffect(() => {
    if (shouldFetch && userId) {
      setIsLoading(true)
      mutate().finally(() => {
        setIsLoading(false)
      })
    }
  }, [userId, shouldFetch, mutate])

  return {
    userProfile: data as UserProfile | undefined,
    isLoading,
    isError: error,
    mutate,
  }
}

function removeNullProperties(obj: UserSearch): UserSearch {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (value === null) return false
      if (key === 'searchTerm' && value === '') return false
      if (key === 'organizationIds' && Array.isArray(value) && value.length === 0) return false
      return true
    })
  )
}

export function useSearchUsers(token: string, params: UserSearch, shouldFetch: boolean = true) {
  const filteredParams = removeNullProperties(params)

  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`users/search`, token, JSON.stringify(filteredParams)] : null,
    ([url, token, params]) => postWithToken(url, token, JSON.parse(params))
  )

  return {
    users: data?.users as User[],
    pagination: data?.paginationInfo as PaginationInfo,
    isLoading,
    isError: error,
  }
}

export function useSearchUsersWithPlanInfo(token: string, queryParams: UserQueryParams, shouldFetch: boolean = true) {
  let params = generateDefaultQueryParams(queryParams)
  params.push(['organizationIds', queryParams.organizationIds.join(',')])
  params.push(['includeWithoutPlans', queryParams.includeWithoutPlans ? 'true' : 'false'])
  params.push(['includeUnapproved', queryParams.includeUnapproved ? 'true' : 'false'])
  params.push(['includeApproved', queryParams.includeApproved ? 'true' : 'false'])
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`users/search-with-plan-info`, token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    users: data?.users as UserWithPlanInfo[],
    pagination: data?.paginationInfo as PaginationInfo,
    isLoading,
    isError: error,
  }
}

interface UseCreateUserReturn {
  createUser: (userDTO: CreateUserDTO) => Promise<User>
  isLoading: boolean
  error: Error | null
  createdUser: User | null
}

export function useCreateUser(token: string): UseCreateUserReturn {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [createdUser, setCreatedUser] = useState<User | null>(null)

  const createUser = useCallback(
    (userDTO: CreateUserDTO): Promise<User> => {
      setIsLoading(true)
      setError(null)
      setCreatedUser(null)

      return postWithToken('users', token, userDTO)
        .then((response) => {
          setCreatedUser(response as User)
          return response as User
        })
        .catch((err) => {
          const error = err instanceof Error ? err : new Error('An error occurred')
          setError(error)
          throw error
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [token]
  )

  return {
    createUser,
    isLoading,
    error,
    createdUser,
  }
}

interface UseUpdateUserReturn {
  updateUser: (userId: string, userDTO: UpdateUserDTO) => Promise<User>
  isLoading: boolean
  error: Error | null
  updatedUser: User | null
}

export function useUpdateUser(token: string): UseUpdateUserReturn {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [updatedUser, setUpdatedUser] = useState<User | null>(null)

  const updateUser = useCallback(
    (userId: string, userDTO: UpdateUserDTO): Promise<User> => {
      setIsLoading(true)
      setError(null)
      setUpdatedUser(null)

      return putWithToken(`users/${userId}`, token, userDTO)
        .then((response) => {
          setUpdatedUser(response as User)
          // Invalidate the cache for this user and any relevant user lists
          mutate(
            (key) => typeof key === 'string' && (key.includes(`users/${userId}`) || key.startsWith('users')),
            undefined,
            true
          )
          return response as User
        })
        .catch((err) => {
          const error = err instanceof Error ? err : new Error('An error occurred')
          setError(error)
          throw error
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [token]
  )

  return {
    updateUser,
    isLoading,
    error,
    updatedUser,
  }
}

export const useDeleteUserFromOrganization = (token: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const deleteUser = async (userId: string, organizationId: string) => {
    setIsLoading(true)
    setError(null)
    try {
      const { status, data } = await deleteWithTokenWithStatus(`organizations/${organizationId}/users/${userId}`, token)
      return { success: status === 200, status, data }
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to delete user'))
      return { success: false, status: 500, data: null }
    } finally {
      setIsLoading(false)
    }
  }

  return { deleteUser, isLoading, error }
}
