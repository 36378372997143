import { CardContent, Divider, Grid2 as Grid, Tooltip, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'
import React from 'react'
import { ExpandedApprovalItem } from 'types/approvals'

const formatCurrency = (value: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)

interface AccrualLedgerTableProps {
  items: ExpandedApprovalItem[]
}

const AccrualLedgerTable: React.FC<AccrualLedgerTableProps> = ({ items }) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h3" mb={2}>
        Accrual Ledger
      </Typography>
      <Paper sx={{ paddingTop: 1, width: '100%', marginBottom: '40px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid size={{ xs: 4 }}>
              <Typography variant="h5">Line Item</Typography>
            </Grid>
            <Grid size={{ xs: 3 }}>
              <Typography variant="h5">Account</Typography>
            </Grid>
            <Grid size={{ xs: 2 }}>
              <Typography variant="h5" align="center">
                FY to Date Balance
              </Typography>
            </Grid>
            <Grid size={{ xs: 2 }}>
              <Typography variant="h5" align="center">
                BalanceChange
              </Typography>
            </Grid>
          </Grid>
          {items.map((item, index) => {
            const isDeleted = !!item.accrualLedgerItem?.deletedAt
            const rowContent = (
              <div key={index}>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Divider />
                </div>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{
                    opacity: isDeleted ? 0.5 : 1,
                    pointerEvents: isDeleted ? 'none' : 'auto',
                  }}
                >
                  <Grid size={{ xs: 4 }}>
                    <Typography>{`${item.accrualLedgerItem?.userFirstName} ${item.accrualLedgerItem?.userLastName} | ${item.accrualLedgerItem?.periodLabel} | ${item.accrualLedgerItem?.allocationTableName || ''} | ${item.accrualLedgerItem?.allocationRowName || ''}`}</Typography>
                  </Grid>
                  <Grid size={{ xs: 3 }}>
                    <Typography>{item.accrualLedgerItem?.ledgerAccountLabel}</Typography>
                  </Grid>
                  <Grid size={{ xs: 2 }}>
                    <Typography align="center">{formatCurrency(item.accrualLedgerItem?.balance || 0)}</Typography>
                  </Grid>
                  <Grid size={{ xs: 2 }}>
                    <Typography align="center">
                      {formatCurrency(
                        (item.accrualLedgerItem?.balance || 0) - (item.accrualLedgerItem?.previousBalance || 0)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )

            return isDeleted ? (
              <Tooltip title="This item has been deleted" key={index}>
                {rowContent}
              </Tooltip>
            ) : (
              rowContent
            )
          })}
        </CardContent>
      </Paper>
    </div>
  )
}

export default AccrualLedgerTable
