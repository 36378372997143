import { ID, OrganizationIDs, PeriodIDs, Revision, Timestamps } from './common'
import { UserRole } from './role'
import { User } from './user'

export interface PlanMetadata extends ID, OrganizationIDs, PeriodIDs, Revision, Timestamps {
  effectiveDate: string
  endDate: string | null
  organizationName: string
  basePlus: boolean
  baseSalary: number
  variablePercent: number
  variableCap: number
  periodProration: number
  finalized: boolean
}

export interface UserProfile {
  user: User
  roles: UserRole[]
  plans?: PlanMetadata[]
}

export const getOrganizationLabelMap = (userProfile: UserProfile): Record<string, string> => {
  return userProfile.roles.reduce(
    (acc: Record<string, string>, role) => {
      acc[role.organization.id] = role.organization.name
      return acc
    },
    {} as Record<string, string>
  )
}

export const getRootOrganizationId = (userProfile: UserProfile): string | null => {
  if (userProfile.roles.length > 0) {
    return userProfile.roles[0].organization.rootOrganizationId
  }
  return null
}

export const getTopParentOrganizationId = (userProfile: UserProfile): string | null => {
  const rootOrg = userProfile.roles.find((role) => role.organization.rootOrganizationId === role.organization.id)
  return rootOrg ? rootOrg.organization.id : null
}

export const getOrganizationIdsWithPermissions = (
  userProfile: UserProfile,
  requiredPermissions: string[]
): string[] => {
  if (
    userProfile === null ||
    userProfile === undefined ||
    userProfile.roles === null ||
    userProfile.roles === undefined
  ) {
    return []
  }

  return userProfile.roles.reduce((acc: string[], role) => {
    const hasAllPermissions = requiredPermissions.every((permission) => role.role.permissions.includes(permission))

    if (hasAllPermissions && !acc.includes(role.organization.id)) {
      acc.push(role.organization.id)
    }

    return acc
  }, [])
}

export const getUniqueOrganizationRoles = (userProfile: UserProfile, memberRolesOnly = false): UserRole[] => {
  const priorityRoles = ['Super-Admin', 'Admin', 'Sub-Admin', 'Member', 'Guest']

  const roles = memberRolesOnly ? userProfile.roles.filter((role) => role.role.name === 'Member') : userProfile.roles

  // Create a map to store the highest priority role for each organization
  const orgRoleMap = roles.reduce((acc: Record<string, UserRole>, role) => {
    const orgId = role.organization.id
    const currentPriority = priorityRoles.indexOf(role.role.name)
    const existingRole = acc[orgId]

    // If no existing role for this org, or current role has higher priority (lower index)
    if (!existingRole || priorityRoles.indexOf(existingRole.role.name) > currentPriority) {
      acc[orgId] = role
    }

    return acc
  }, {})

  // Convert map back to array
  return Object.values(orgRoleMap)
}

export const getMemberRole = (userProfile: UserProfile): UserRole | null => {
  return userProfile.roles.find((role) => role.role.name === 'Member') || null
}

export const getActivePlans = (userProfile: UserProfile, currentDate: string): PlanMetadata[] => {
  if (!userProfile.plans) {
    return []
  }

  return userProfile.plans.filter((plan) => {
    const effectiveDate = new Date(plan.effectiveDate)
    const effectiveEndDate = plan.endDate ? new Date(plan.endDate) : null
    const now = new Date(currentDate)

    return effectiveDate <= now && (!effectiveEndDate || effectiveEndDate >= now)
  })
}

export const getPlansByOrganizationId = (userProfile: UserProfile, organizationId: string): PlanMetadata[] => {
  if (!userProfile.plans) {
    return []
  }

  return userProfile.plans.filter((plan) => plan.organizationId === organizationId)
}

export const getLatestPlanByPeriod = (userProfile: UserProfile, periodId: string): PlanMetadata | null => {
  if (!userProfile.plans) {
    return null
  }

  const plansForPeriod = userProfile.plans.filter((plan) => plan.periodId === periodId)

  if (plansForPeriod.length === 0) {
    return null
  }

  // Sort by revision in descending order and return the latest plan
  return plansForPeriod.sort((a, b) => b.revision - a.revision)[0]
}
