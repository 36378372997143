import { Grid2 as Grid } from '@mui/material'
import React from 'react'

import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'

import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'
import PlanAllocationTables from './PlanAllocationTables'
import PlanComponentSummary from './PlanComponentSummary'
import PlanTargets from './PlanTargets'

const ComponentSelectionForm = () => {
  const { plan, employeeName, compensationTemplate } = useCreatePlanContext()

  return (
    <React.Fragment>
      <Grid container spacing={0} alignItems="center">
        <Grid size={{ xs: 12 }}>
          <CompensationSummaryCard
            basePlus={plan.basePlus}
            variablePercent={plan.variablePercent}
            baseSalary={plan.baseSalary}
            variableCap={plan.variableCap}
            name={employeeName}
            position={plan.position}
            effectiveDate={plan.effectiveDate}
            period={compensationTemplate?.period}
            periodId={plan.periodId}
            periodProration={plan.periodProration}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <PlanTargets />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <PlanComponentSummary />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <PlanAllocationTables />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ComponentSelectionForm
