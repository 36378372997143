import { useGlobalState } from 'contexts/GlobalStateContext'
import React from 'react'
import useSWR from 'swr'
import { MFAEnrollmentsResponse } from 'types/auth'
import { Role, UserRole } from 'types/role'
import { UserProfile } from 'types/user-profile'
import { deleteWithTokenWithStatus, fetchWithTokenWithStatus, postWithTokenWithStatus } from './http'

export function useProfile(token: string, forceFresh: boolean = false) {
  const { purcentAppState, onChangeOrganizationSelection } = useGlobalState()
  const shouldFetch = token != null && token.trim() !== ''

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetch ? [`profile`, token] : null,
    ([url, token]) => fetchWithTokenWithStatus(url, token, null),
    {
      suspense: true,
      revalidateIfStale: forceFresh,
      revalidateOnFocus: false,
      revalidateOnMount: forceFresh,
    }
  )

  // Assuming `data` contains the user profile with roles and organizations
  if (data?.data && data.data.roles && data.data.roles.length > 0 && !purcentAppState?.selectedOrganization) {
    // Find the first root organization in the user's roles
    const firstRootOrg = data.data.roles.find(
      (role: UserRole) => role.organization.rootOrganizationId === role.organization.id
    )?.organization
    if (firstRootOrg) {
      onChangeOrganizationSelection(firstRootOrg)
    }

    // Just get the first organization if there is no selected organization
    if (!purcentAppState?.selectedOrganization && data.data.roles.length > 0) {
      onChangeOrganizationSelection(data.data.roles[0].organization)
    }
  }

  const refetch = () => mutate()

  return {
    profile: data?.data as UserProfile,
    status: data?.status,
    isLoading: !data && isValidating,
    isError: error || data?.status !== 200,
    refetch,
  }
}

export function useRoles(token: string, forceFresh: boolean = false) {
  const { purcentAppState } = useGlobalState()
  const params: [string, string][] = []
  if (purcentAppState?.selectedOrganization?.rootOrganizationId) {
    params.push(['rootOrganizationId', purcentAppState?.selectedOrganization?.rootOrganizationId])
  }

  const { data, error, isLoading } = useSWR(
    [`roles`, token, params],
    ([url, token, params]) => fetchWithTokenWithStatus(url, token, params),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
      dedupingInterval: forceFresh ? 0 : 24 * 60 * 60 * 1000, // 0 if forceFresh, else 24 hours
      revalidateOnMount: forceFresh,
    }
  )

  return {
    roles: (data?.data as Role[]) || [],
    isLoading,
    isError: error,
  }
}

export function usePasswordReset(token: string) {
  const triggerPasswordReset = React.useCallback(async () => {
    try {
      const response = await postWithTokenWithStatus('auth/password-reset', token, {})
      return response
    } catch (error) {
      console.error('Error triggering password reset:', error)
      throw error
    }
  }, [token])

  return { triggerPasswordReset }
}

export function useMFAEmail(token: string) {
  const triggerMFAEmail = React.useCallback(async () => {
    try {
      const response = await postWithTokenWithStatus('auth/mfa-email', token, {})
      return response
    } catch (error) {
      console.error('Error triggering MFA email:', error)
      throw error
    }
  }, [token])

  return { triggerMFAEmail }
}

export function useSignOutAll(token: string) {
  const triggerSignOutAll = React.useCallback(async () => {
    try {
      const response = await postWithTokenWithStatus('auth/sign-out-all', token, {})
      return response
    } catch (error) {
      console.error('Error signing out of all devices:', error)
      throw error
    }
  }, [token])

  return { triggerSignOutAll }
}

export function useMFAEnrollments(token: string, shouldFetch: boolean = true) {
  const key = shouldFetch ? ['auth/mfa-enrollments', token] : null
  const { data, error, isLoading, mutate } = useSWR<MFAEnrollmentsResponse>(
    key,
    ([url, token]) => fetchWithTokenWithStatus(url, token, null),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
    }
  )

  const deleteMFAEnrollment = React.useCallback(
    async (enrollmentId: string) => {
      try {
        const response = await deleteWithTokenWithStatus(`auth/mfa-enrollments/${enrollmentId}`, token)
        await mutate() // Refetch the enrollments after deletion
        return response
      } catch (error) {
        console.error('Error deleting MFA enrollment:', error)
        throw error
      }
    },
    [token, mutate]
  )

  const revalidate = () => {
    if (key) {
      mutate()
    }
  }

  return {
    mfaEnrollments: data?.data || [],
    isLoading,
    isError: error,
    deleteMFAEnrollment,
    revalidate,
  }
}
