import { EditOutlined } from '@ant-design/icons'
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material'
import { useFetchApprovalBatches } from 'hooks/useApprovals'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ApprovalBatchStatus } from 'types/approvals'

const ApprovalsTile: React.FC = () => {
  const navigate = useNavigate()
  const { token } = useAuth()
  const params = {
    batchTypes: [],
    page: 0,
    perPage: 10,
    status: ApprovalBatchStatus.PENDING,
    label: '',
  }
  const { approvals, isLoading } = useFetchApprovalBatches(token!, params)

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ padding: 2 }}>
          <Typography fontSize={14} fontWeight={500} color="info" sx={{ mb: 2 }}>
            Pending Approvals
          </Typography>
          {approvals?.batches?.length > 0 ? (
            <>
              {approvals.batches.map((batch) => (
                <Box key={batch.id} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {batch.label}
                  </Typography>
                  <IconButton onClick={() => navigate(`/admin/approvals/${batch.id}/edit`)}>
                    <EditOutlined />
                  </IconButton>
                </Box>
              ))}
              <Button onClick={() => navigate('/admin/approvals')}>See more approvals</Button>
            </>
          ) : (
            <Typography variant="body1" color="text.secondary" fontSize={12} sx={{ marginTop: 2 }}>
              No pending approvals
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

export default ApprovalsTile
