export default {
  // Primary Colors
  caribbeanBlue: '#087575',
  turquoise: '#1DD3B0',

  // Accent Colors
  charcoalBlue: '#193C4D',
  pear: '#D1E231',
  kellyGreen: '#32AE2C',
  crayolaRed: '#F00553',

  // Neutral Colors
  black: '#000000',
  gunmetal: '#1C252C',
  // mediumGray: '#8E979D',
  mediumGray: '#8C8C8C',
  lightGray: '#F5F5F5',
  white: '#FFFFFF',
}
