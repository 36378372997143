import React, { useCallback, useState } from 'react'
import useSWR from 'swr'
import {
  Accrual,
  AccrualLedgerQueryParams,
  AccrualLedgerResponse,
  AccrualsQueryParams,
  AccrualsResponse,
  PrepareLedgerItem,
  UsePrepareLedgerParams,
} from 'types/accruals'
import { generateDefaultQueryParams } from 'types/default-params'
import { fetchWithToken, fetchWithTokenWithStatus } from './http'

export function useAccruals(token: string, queryParams: AccrualsQueryParams, shouldFetch: boolean = true) {
  let params = generateDefaultQueryParams(queryParams)
  params.push(['periodIds', queryParams.periodIds.join(',')])
  params.push(['organizationIds', queryParams.organizationIds.join(',')])
  params.push(['status', queryParams.status])
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch ? [`accruals`, token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  const refetch = React.useCallback(() => {
    mutate()
  }, [mutate])

  return {
    accruals: data ? (data as AccrualsResponse).accruals : [],
    pagination: data ? (data as AccrualsResponse).pagination : null,
    isLoading,
    isError: error,
    refetch,
  }
}

export function useApprovedAccruals(token: string, targetUserId: string | null) {
  const { data, error, isLoading } = useSWR(
    token ? ['accruals/approved', token, targetUserId] : null,
    ([url, token, targetUserId]) =>
      fetchWithToken(targetUserId ? `${url}?targetUserId=${targetUserId}` : url, token, null)
  )

  return {
    approvedAccruals: data as Accrual[] | undefined,
    isLoading,
    isError: error,
  }
}

export function usePrepareLedger({
  token,
  rootOrganizationId,
  rootPeriodId,
  periodIds,
  shouldFetch = true,
}: UsePrepareLedgerParams) {
  const [isLoading, setIsLoading] = useState(false)
  const params = new URLSearchParams()
  params.append('rootOrganizationId', rootOrganizationId)
  params.append('rootPeriodId', rootPeriodId)
  periodIds.forEach((id) => params.append('periodIds', id))

  const { data, error, mutate } = useSWR(
    shouldFetch ? ['accruals/prepare-ledger', token, params.toString()] : null,
    ([url, token, queryString]) => {
      setIsLoading(true)
      return fetchWithTokenWithStatus(`${url}?${queryString}`, token, null)
        .then((result) => {
          setIsLoading(false)
          return result
        })
        .catch((err) => {
          setIsLoading(false)
          throw err
        })
    }
  )

  const refetch = useCallback(() => {
    setIsLoading(true)
    mutate()
  }, [mutate])

  return {
    ledgerItems: data?.status === 200 ? (data.data as PrepareLedgerItem[]) : undefined,
    isLoading,
    isError: error || (data?.status !== 200 ? data?.data?.error : undefined),
    refetch,
    status: data?.status,
  }
}

export function useAccrualLedger({
  token,
  queryParams,
  shouldFetch = true,
}: {
  token: string
  queryParams: AccrualLedgerQueryParams
  shouldFetch?: boolean
}) {
  const [isLoading, setIsLoading] = useState(false)
  let params = generateDefaultQueryParams(queryParams)

  if (queryParams.organizationIds?.length) {
    params.push(['organizationIds', queryParams.organizationIds.join(',')])
  }
  if (queryParams.periodIds?.length) {
    params.push(['periodIds', queryParams.periodIds.join(',')])
  }
  if (queryParams.ledgerAccountId) {
    params.push(['ledgerAccountId', queryParams.ledgerAccountId])
  }

  const { data, error, mutate } = useSWR(
    shouldFetch ? ['accruals/ledger', token, params] : null,
    ([url, token, params]) => {
      setIsLoading(true)
      return fetchWithTokenWithStatus(`${url}?${new URLSearchParams(params)}`, token, null)
        .then((result) => {
          setIsLoading(false)
          return result
        })
        .catch((err) => {
          setIsLoading(false)
          throw err
        })
    }
  )

  const refetch = useCallback(() => {
    setIsLoading(true)
    mutate()
  }, [mutate])

  return {
    accrualLedgers: data?.status === 200 ? (data.data as AccrualLedgerResponse).accrualLedgers : undefined,
    pagination: data?.status === 200 ? (data.data as AccrualLedgerResponse).pagination : undefined,
    isLoading,
    isError: error || (data?.status !== 200 ? data?.data?.error : undefined),
    refetch,
    status: data?.status,
  }
}
