import { UserRole } from './role'
import { Feature } from './user'
import { getRootOrganizationId, UserProfile } from './user-profile'

export type FeatureFlagLevel = 'org' | 'user'

export type FeatureFlagType = {
  name: string
  level: FeatureFlagLevel
}

export const checkFeatureFlag = (featureFlag: FeatureFlagType, profile: UserProfile, profileIsLoading: boolean) => {
  if (profileIsLoading) {
    return false
  }

  let isFeatureTrue = false
  if (featureFlag.level === 'org') {
    console.log('Org Level Feature Gating is not yet implemented')
    // get the root org for the user
    const rootOrg = getRootOrganizationId(profile)
    // find the role with the rootOrgId
    const role = profile.roles.find((role: UserRole) => role.organization.id === rootOrg)
    if (role) {
      isFeatureTrue = role.organization.metadata.features.some(
        (feature: Feature) => feature[featureFlag.name] === 'true'
      )
    }
  } else {
    isFeatureTrue = profile.user.metadata?.features?.some((feature) => feature[featureFlag.name] === 'true') ?? false
  }
  return isFeatureTrue
}
