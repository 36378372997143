import { useTheme } from '@mui/material/styles'
import React from 'react'

// material-ui
import { Box, Typography } from '@mui/material'
import Paper from 'components/@extended/Paper'

interface CompensationSummaryTileProps {
  label: string
  value: number
  formatter: Intl.NumberFormat | null
  width: string
  footer?: string | null
  footerTextColor?: string | null
  footerBackgroundColor?: string | null
  border?: boolean | null
  borderColor?: string | null
  backgroundColor?: string | null
}

const CompensationSummaryTile = ({
  label,
  value,
  formatter,
  width,
  footer,
  footerTextColor,
  footerBackgroundColor,
  border,
  borderColor,
  backgroundColor,
}: CompensationSummaryTileProps) => {
  const theme = useTheme()

  // Adjust primary text color for light mode
  const primaryTextColor = theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.text.primary

  return (
    <React.Fragment>
      <Paper
        sx={{
          background: backgroundColor || theme.palette.background.paper,
          color: primaryTextColor, // Use adjusted text color
          padding: 0,
          borderRadius: 2,
          border: border ? `1px solid ${borderColor || theme.palette.divider}` : 'none',
        }}
      >
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', pl: 3, pt: 2, pb: 0 }}>
          {label}
        </Typography>
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', my: 1, pl: 3, pt: 0 }}>
          {formatter ? formatter.format(value) : value}
        </Typography>

        <Box
          sx={{
            fontSize: '0.75rem',
            padding: 0,
            borderTop: '2px solid',
            borderColor: 'divider',
            minHeight: '3rem',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              py: 1,
              pl: 3,
              pr: 2,
            }}
          >
            {footer ? footer : ''}
          </Typography>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default CompensationSummaryTile
