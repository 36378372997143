import { CloseCircleOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import Paper from 'components/@extended/Paper'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import SearchInput from 'components/SearchInput'
import useAuth from 'hooks/useAuth'
import useLocalStorage from 'hooks/useLocalStorage'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import { useSearchOrganizationTemplates } from 'hooks/useTemplates'
import type { JSX } from 'react'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TemplateQueryParams } from 'types/comp-template'
import { createIdLabelMap, getClosedPeriodIds } from 'types/periods'
import { KeyedObject } from 'types/root'
import { getOrganizationLabelMap } from 'types/user-profile'
import { debounce } from 'utils/debounce'
import { wholePercentFormatter } from 'utils/formatting'

interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: KeyedObject) => string | boolean | JSX.Element
}

const SelectTemplate: FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const navigate = useNavigate()
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [sort, setSort] = useLocalStorage<string>('template-select-sort', 'label')
  const [order, setOrder] = useLocalStorage<string>('template-select-order', 'asc')
  const tableContainerRef = useRef(null)
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [rootOrganizationId, setRootOrganizationId] = useState<string>('')

  const { token } = useAuth()
  const { profile } = useProfile(token!)

  const { periods, isLoadingPeriods } = usePeriods(token!, rootOrganizationId, rootOrganizationId !== '')

  const queryParams: TemplateQueryParams = {
    organizationIds: selectedOrganizations,
    searchTerm: search,
    periodIds: selectedPeriods.filter((id) => {
      const closedPeriodIds = getClosedPeriodIds(periods?.periods || [])
      return !closedPeriodIds.includes(id)
    }),
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: [{ field: sort, order: order }],
  }

  const { templates, isLoading } = useSearchOrganizationTemplates(token!, queryParams)

  useEffect(() => {
    if (tableContainerRef.current) {
      ;(tableContainerRef.current as HTMLElement).scrollTop = 0
    }
  }, [sort, order, page, rowsPerPage])

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setSearch(value)
    }, 300),
    []
  )

  const organizationMap = profile != null ? getOrganizationLabelMap(profile) : {}

  const columns: ColumnProps[] = [
    { id: 'label', label: 'Name', minWidth: 300 },
    {
      id: 'organizationName',
      label: 'Team',
      minWidth: 200,
      format: (value: any, row: any) => {
        if (Array.isArray(row.organizationIds) && row.organizationIds.length >= 4) {
          return `(${row.organizationIds.length}) Teams`
        }

        return Array.isArray(row.organizationIds)
          ? row.organizationIds.map((id: string) => organizationMap[id]).join(', ')
          : organizationMap[row.organizationIds]
      },
    },
    { id: 'position', label: 'Position', minWidth: 170 },
    {
      id: 'variable_percent',
      label: 'Variable Compensation',
      minWidth: 180,
      format: (value: any, row: any) => wholePercentFormatter.format(row.variablePercent),
    },
    {
      id: 'id',
      label: 'Action',
      minWidth: 180,
      format: (templateId: string, row: any) => {
        const closedPeriodIds = getClosedPeriodIds(periods?.periods || [])
        const isDisabled = closedPeriodIds.includes(row.periodId)

        return (
          <Tooltip title={isDisabled ? 'Cannot select template with closed periods' : ''}>
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/create-comp-plan?withUserId=${userId}&templateId=${templateId}`)}
                disabled={isDisabled}
              >
                Select
              </Button>
            </span>
          </Tooltip>
        )
      },
    },
  ]

  useEffect(() => {
    if (profile) {
      const org = profile.roles.find((role) => role.organization.rootOrganizationId != null)
      if (org) {
        setRootOrganizationId(org.organization.rootOrganizationId!)
      }
    }
  }, [profile])

  useEffect(() => {
    if (periods?.periods && selectedPeriods.length === 0) {
      const allPeriodIds = periods.periods.flatMap((period) => {
        const idLabelMap = createIdLabelMap(period)
        const closedPeriodIds = getClosedPeriodIds(periods.periods)
        // Filter out closed periods from the map
        const openPeriodIds = Object.keys(idLabelMap).filter((id) => !closedPeriodIds.includes(id))
        return openPeriodIds
      })
      setSelectedPeriods(allPeriodIds)
    }
  }, [periods])

  return (
    <div style={{ position: 'relative' }}>
      <Grid container spacing={4} mb={3}>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h2">Select Template</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4} mb={3}>
        <Grid size={{ xs: 3 }}>
          <SearchInput
            id="template-search-input"
            key="template-search-input"
            onSearch={debouncedSetSearch}
            initialValue={search}
            labelBackgroundColor={theme.palette.background.default}
          />
        </Grid>
        <Grid size={{ xs: 9 }}>
          <Box display="flex" gap={2} justifyContent="flex-end" alignItems="center">
            {isLoadingPeriods ? (
              <CircularProgress size={20} />
            ) : (
              <MultiPeriodSelector
                periods={periods != null ? periods.periods : []}
                selectedPeriods={selectedPeriods}
                handleChange={setSelectedPeriods}
                label="Filter Periods"
                labelBackgroundColor={theme.palette.background.default}
                width="200px"
                disableClosed={true}
              />
            )}
            <MultiOrganizationSelector
              userProfile={profile}
              selectedOrganizationIds={selectedOrganizations}
              handleChange={setSelectedOrganizations}
              selectAll={true}
              labelBackgroundColor={theme.palette.background.default}
              width="350px"
            />
            <Tooltip title="Clear Filters">
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedOrganizations([])
                  setSelectedPeriods([])
                  setSearch('')
                }}
              >
                <CloseCircleOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={12}>
          <Grid
            size={{ xs: 12 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress color="primary" />
            Loading
          </Grid>
        </Grid>
      ) : (
        <Paper>
          <TableContainer ref={tableContainerRef} sx={{ maxHeight: 860, minHeight: 430 }}>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.id !== 'label' && column.id !== 'position' ? (
                        column.label
                      ) : (
                        <TableSortLabel
                          active={sort === column.id}
                          direction={sort === column.id ? (order as 'asc' | 'desc') : 'asc'}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.length > 0 ? (
                  templates.map((row: KeyedObject) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value, row) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={templates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  )
}

export default SelectTemplate
