import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { useCallback, useState } from 'react'
import useSWR from 'swr'
import {
  CreateOrganizationDTO,
  ExpandedOrganization,
  ExpandedOrganizationHierarchy,
  Organization,
  OrganizationCoreSetting,
  OrganizationSetting,
  UpdateOrganizationDTO,
  buildOrganizationHierarchy,
} from 'types/organization' // Adjust the import path as necessary
import { getUniqueOrganizationRoles } from 'types/user-profile'
import { deleteWithToken, fetchWithToken, postWithToken, putWithToken } from './http'

interface UseOrganizationSettingsReturn {
  settings: OrganizationSetting[]
  isLoading: boolean
  isError: any
  saveSettings: (settings: OrganizationSetting[]) => Promise<void>
  refetch: () => Promise<void>
}

interface UseOrganizationReturn {
  organization: Organization | null
  isLoading: boolean
  isError: any
  refetch: () => Promise<Organization | null>
}

interface UseExpandedOrganizationReturn {
  organizationHierarchy: ExpandedOrganizationHierarchy | null
  isLoading: boolean
  isError: any
  refetch: () => Promise<ExpandedOrganizationHierarchy | null>
}

interface UseCreateOrganizationReturn {
  createOrganization: (organizationDTO: CreateOrganizationDTO) => void
  isLoading: boolean
  error: Error | null
  createdOrganization: Organization | null
}

interface UseDeleteOrganizationReturn {
  deleteOrganization: (organizationId: string) => Promise<void>
  isLoading: boolean
  error: Error | null
  isSuccess: boolean
}

interface UseOrganizationCoreSettingsReturn {
  coreSettings: OrganizationCoreSetting[]
  isLoading: boolean
  isError: any
  refetch: () => Promise<OrganizationCoreSetting[]>
}

interface UseUpdateOrganizationReturn {
  updateOrganization: (organizationId: string, updateDTO: UpdateOrganizationDTO) => Promise<void>
  isLoading: boolean
  error: Error | null
  isSuccess: boolean
}

export function useFetchOrganization(organizationId: string, token: string): UseOrganizationReturn {
  const shouldFetch = organizationId != null && organizationId.trim() !== '' && token != null && token.trim() !== ''

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetch ? [`organizations/${organizationId}`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  )

  const refetch = async (): Promise<Organization | null> => {
    // Assuming fetchWithToken returns the data directly, you might need to adjust this based on your actual implementation
    const refetchedData = await mutate()
    return refetchedData as Organization | null
  }

  return {
    organization: data as Organization | null,
    isLoading: !data && isValidating,
    isError: error,
    refetch, // Now includes the refetch function
  }
}

export function useFetchOrganizationSettings(organizationId: string, token: string): UseOrganizationSettingsReturn {
  const shouldFetch = organizationId != null && organizationId.trim() !== '' && token != null && token.trim() !== ''

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetch ? [`organizations/${organizationId}/settings`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: false,
      revalidateIfStale: true,
      revalidateOnFocus: false,
    }
  )

  const saveSettings = async (settings: OrganizationSetting[]): Promise<void> => {
    if (!organizationId || !token || !settings) {
      console.error('Missing required data for saving settings')
      return
    }

    const url = `organizations/${organizationId}/settings`
    try {
      const saveResponse = await postWithToken(url, token, settings)

      if (saveResponse && saveResponse.message && saveResponse.message === 'Settings Saved') {
        // Using mutate() without parameters to revalidate and refetch data
        await mutate()
        return
      }

      console.error('Failed to save settings: Unexpected response')
    } catch (error) {
      console.error('Error saving settings:', error)
    }
  }

  const refetch = async (): Promise<void> => {
    // Using mutate() without parameters to revalidate and refetch data
    await mutate()
  }

  return {
    settings: data as OrganizationSetting[],
    isLoading: !data && isValidating,
    isError: error,
    saveSettings,
    refetch, // Include the refetch function in the return value
  }
}

export function useFetchExpandedOrganization(
  organizationId: string,
  token: string,
  forceFresh: boolean = false
): UseExpandedOrganizationReturn {
  const shouldFetch = organizationId != null && organizationId.trim() !== '' && token != null && token.trim() !== ''

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetch ? [`organizations/${organizationId}/expanded`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: true,
      revalidateIfStale: forceFresh,
      revalidateOnFocus: forceFresh,
      revalidateOnReconnect: forceFresh,
      refreshInterval: 0,
      revalidateOnMount: forceFresh,
    }
  )

  const buildHierarchy = useCallback((organizations: ExpandedOrganization[]) => {
    try {
      const hierarchy = buildOrganizationHierarchy(organizations)
      return hierarchy
    } catch (error) {
      // Handle the error appropriately
      return null
    }
  }, [])

  const organizationHierarchy = data ? buildHierarchy(Object.values(data)) : null

  const refetch = async (): Promise<ExpandedOrganizationHierarchy | null> => {
    const refetchedData = await mutate()
    return refetchedData ? buildHierarchy(refetchedData) : null
  }

  return {
    organizationHierarchy,
    isLoading: !data && isValidating,
    isError: error,
    refetch,
  }
}

export function useHasAllOrganizationPermissions(organizationId: string, requiredPermissions: string[]) {
  const { token } = useAuth()
  const { profile, isLoading, isError } = useProfile(token!)

  const hasPermissions = () => {
    if (profile === null || profile === undefined || profile.roles === null || profile.roles === undefined) {
      console.error('Profile is null or undefined')
      return false
    }

    // Collect all permissions from roles within the specified organization
    const permissions = getUniqueOrganizationRoles(profile)
      .filter((role) => role.organization.id === organizationId)
      .flatMap((role) => role.role.permissions)

    // Check if all required permissions are included in the collected permissions
    return requiredPermissions.every((permission) => permissions.includes(permission))
  }

  return {
    hasPermissions: !isLoading && !isError && hasPermissions(),
    isLoading,
    isError,
  }
}

export function useHasAnyOrganizationPermissions(requiredPermissions: string[]) {
  const { token } = useAuth()
  const { profile, isLoading, isError } = useProfile(token!)

  const hasPermissions = () => {
    if (!profile) return false

    // Collect all permissions from all roles
    const permissions = getUniqueOrganizationRoles(profile).flatMap((role) => role.role.permissions)

    // Check if all required permissions are included in the collected permissions
    return requiredPermissions.every((permission) => permissions.includes(permission))
  }

  return {
    hasPermissions: !isLoading && !isError && hasPermissions(),
    isLoading,
    isError,
  }
}

export function useCreateOrganization(token: string): UseCreateOrganizationReturn {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [createdOrganization, setCreatedOrganization] = useState<Organization | null>(null)

  const createOrganization = useCallback(
    (organizationDTO: CreateOrganizationDTO) => {
      setIsLoading(true)
      setError(null)
      setCreatedOrganization(null)

      postWithToken('organizations', token, organizationDTO)
        .then((response) => {
          setCreatedOrganization(response as Organization)
        })
        .catch((err) => {
          setError(err instanceof Error ? err : new Error('An error occurred'))
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [token]
  )

  return {
    createOrganization,
    isLoading,
    error,
    createdOrganization,
  }
}

export function useDeleteOrganization(token: string): UseDeleteOrganizationReturn {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [isSuccess, setIsSuccess] = useState(false)

  const deleteOrganization = useCallback(
    async (organizationId: string) => {
      setIsLoading(true)
      setError(null)
      setIsSuccess(false)

      try {
        const response = await deleteWithToken(`organizations/${organizationId}`, token)
        if (response.message === 'Organization deleted successfully') {
          setIsSuccess(true)
        } else {
          throw new Error('Unexpected response from server')
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An error occurred while deleting the organization'))
      } finally {
        setIsLoading(false)
      }
    },
    [token]
  )

  return {
    deleteOrganization,
    isLoading,
    error,
    isSuccess,
  }
}

export function useFetchOrganizationCoreSettings(
  organizationIds: string[],
  token: string
): UseOrganizationCoreSettingsReturn {
  const shouldFetch = organizationIds.length > 0 && token != null && token.trim() !== ''
  const orgIdsParam = organizationIds.join(',')

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetch ? [`organizations/core-settings?orgIds=${orgIdsParam}`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: false,
      revalidateIfStale: true,
      revalidateOnFocus: false,
    }
  )

  const refetch = async (): Promise<OrganizationCoreSetting[]> => {
    const refetchedData = await mutate()
    return refetchedData as OrganizationCoreSetting[]
  }

  return {
    coreSettings: data as OrganizationCoreSetting[],
    isLoading: !data && isValidating,
    isError: error,
    refetch,
  }
}

export function useUpdateOrganization(token: string): UseUpdateOrganizationReturn {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [isSuccess, setIsSuccess] = useState(false)

  const updateOrganization = useCallback(
    async (organizationId: string, updateDTO: UpdateOrganizationDTO) => {
      setIsLoading(true)
      setError(null)
      setIsSuccess(false)

      try {
        const response = await putWithToken(`organizations/${organizationId}`, token, updateDTO)
        if (response && response.message === 'Organization updated successfully') {
          setIsSuccess(true)
        } else {
          throw new Error('Unexpected response from server')
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err)
        } else {
          setError(new Error('An error occurred while updating the organization'))
        }
      } finally {
        setIsLoading(false)
      }
    },
    [token]
  )

  return {
    updateOrganization,
    isLoading,
    error,
    isSuccess,
  }
}
