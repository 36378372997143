import { Theme } from '@mui/material/styles'

export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.info.light,
        },
        outlined: {
          lineHeight: '1rem',
          top: -4,
          '&.MuiInputLabel-sizeSmall': {
            lineHeight: '1em',
          },
          '&.MuiInputLabel-shrink': {
            color: theme.palette.primary.main,

            padding: '0 8px',
            marginLeft: -6,
            top: 2,
            lineHeight: '1rem',
          },
          '&.Mui-focused': {
            fontWeight: 'bold',
          },
        },
      },
    },
  }
}
