// project import

import ThemePurcent from './theme-purcent'

// types
import { PaletteThemeProps } from 'types/theme'
import { PalettesProps } from '@ant-design/colors'
import { ThemeMode, PresetColor } from 'types/config'

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors: PalettesProps, presetColor: PresetColor, mode: ThemeMode): PaletteThemeProps => {
  return ThemePurcent(colors, mode)
}

export default Theme
