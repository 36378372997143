// material-ui
import { Theme } from '@mui/material/styles'

// project import
import getColors from 'utils/getColors'
import getShadow from 'utils/getShadow'

// types
import { ThemeMode } from 'types/config'
import { ColorProps } from 'types/extended'

interface Props {
  variant: ColorProps
  theme: Theme
}

// ==============================|| OVERRIDES - INPUT BORDER & SHADOWS ||============================== //

function getColor({ variant, theme }: Props) {
  const colors = getColors(theme, variant)
  const { light } = colors

  const shadows = getShadow(theme, `${variant}`)

  return {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: light,
    },
    '&.Mui-focused': {
      boxShadow: shadows,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${light}`,
      },
    },
  }
}

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10.5px 14px 10.5px 12px',
        },
        notchedOutline: {
          borderColor: theme.palette.mode === ThemeMode.DARK ? theme.palette.grey[300] : theme.palette.grey[300],
        },
        root: {
          '&.Mui-focused fieldset': {
            color: theme.palette.grey[300],
            borderColor: theme.palette.grey[300],
            borderWidth: '2px',
          },
          '&:hover fieldset': {
            color: theme.palette.grey[300] + ' !important',
            borderColor: theme.palette.grey[300] + ' !important',
            borderWidth: '2px !important',
          },
          '& fieldset': {
            borderColor: theme.palette.grey[300] + ' !important',
          },
        },
        inputSizeSmall: {
          padding: '7.5px 8px 7.5px 12px',
        },
        inputMultiline: {
          padding: 0,
        },
        colorSecondary: getColor({ variant: 'secondary', theme }),
        colorError: getColor({ variant: 'error', theme }),
        colorWarning: getColor({ variant: 'warning', theme }),
        colorInfo: getColor({ variant: 'info', theme }),
        colorSuccess: getColor({ variant: 'success', theme }),
      },
    },
  }
}
