// material-ui
import { Grid2 as Grid, Stack } from '@mui/material'
import Paper from 'components/@extended/Paper'
import { TextField } from 'components/@extended/TextField'
import { useTheme } from '@mui/material/styles'

import dayjs from 'dayjs'

import CompensationChart from 'components/CompensationChart'
import React from 'react'
import CompensationSummaryTile from './CompensationSummaryTile'

import { ThemeMode } from 'types/config'
import { createIdToPeriod, Period } from 'types/periods'
import { currencyFormatter, twoDigitPercentFormatter } from 'utils/formatting'

// ==============================|| REPORT CARD ||============================== //
interface CompensationSummaryCardProps {
  basePlus?: boolean
  variablePercent?: number
  baseSalary?: number
  variableCap?: number
  name?: string
  position?: string
  effectiveDate?: string
  periodProration?: number
  periodId?: string
  period?: Period
}

const CompensationSummaryCard = ({
  basePlus,
  variablePercent,
  baseSalary,
  variableCap,
  periodProration = 1.0,
  name,
  position,
  effectiveDate,
  periodId,
  period,
}: CompensationSummaryCardProps) => {
  const theme = useTheme()

  const footerBackgroundColor =
    theme.palette.mode === ThemeMode.LIGHT ? theme.palette.grey[300] : theme.palette.grey[300]

  const footerTextColor =
    theme.palette.mode === ThemeMode.LIGHT ? theme.palette.info.contrastText : theme.palette.primary.contrastText

  let periodLabel = ''
  if (period) {
    const periodMap = createIdToPeriod(period)
    const compPeriod = periodMap[periodId!]
    periodLabel = compPeriod.label
  }

  return (
    <React.Fragment>
      <Stack spacing={5}>
        {name &&
          position &&
          effectiveDate &&
          basePlus !== undefined &&
          basePlus != null &&
          baseSalary !== undefined &&
          baseSalary != null &&
          variablePercent !== undefined &&
          variablePercent != null &&
          variableCap !== undefined &&
          variableCap != null &&
          periodProration !== undefined &&
          periodProration != null && (
            <Grid size={{ xs: 12 }}>
              <Paper>
                <Grid container sx={{ padding: 2 }} justifyContent="space-around" alignItems="center" spacing={2}>
                  <Grid size={{ xs: 3 }}>
                    <TextField
                      label="Employee Name"
                      value={name}
                      slotProps={{
                        input: {
                          readOnly: true,
                        },
                      }}
                      fullWidth
                      focused
                      variant="outlined"
                      pill
                      shrink
                    />
                  </Grid>

                  <Grid size={{ xs: 3 }}>
                    <TextField
                      label="Position"
                      value={position}
                      slotProps={{
                        input: {
                          readOnly: true,
                        },
                      }}
                      fullWidth
                      focused
                      variant="outlined"
                      pill
                      shrink
                    />
                  </Grid>

                  <Grid size={{ xs: 3 }}>
                    <TextField
                      label="Compensation Period"
                      value={periodLabel}
                      slotProps={{
                        input: {
                          readOnly: true,
                        },
                      }}
                      fullWidth
                      focused
                      variant="outlined"
                      pill
                      shrink
                    />
                  </Grid>

                  <Grid size={{ xs: 3 }}>
                    <TextField
                      label="Effective Date"
                      value={dayjs(effectiveDate).format('YYYY-MM-DD')}
                      slotProps={{
                        input: {
                          readOnly: true,
                        },
                      }}
                      fullWidth
                      focused
                      variant="outlined"
                      pill
                      shrink
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ padding: 2 }} justifyContent="space-around" alignItems="center" spacing={2}>
                  <Grid size={{ xs: 3 }}>
                    <CompensationChart
                      key={'compensation-chart'}
                      salary={
                        basePlus
                          ? baseSalary * periodProration
                          : (baseSalary - baseSalary * variablePercent) * periodProration
                      }
                      variable={
                        basePlus
                          ? baseSalary * variablePercent * periodProration
                          : baseSalary * variablePercent * periodProration
                      }
                    />
                  </Grid>

                  <Grid size={{ xs: 3 }} container direction="column" spacing={2}>
                    <CompensationSummaryTile
                      label="Base Compensation"
                      value={baseSalary * periodProration}
                      formatter={currencyFormatter}
                      width={'40%'}
                      border={true}
                      borderColor={footerBackgroundColor}
                      backgroundColor={'transparent'}
                      footer={
                        periodProration < 1
                          ? basePlus
                            ? `Prorated from an Annual Base Salary of ${currencyFormatter.format(baseSalary)}`
                            : `Prorated from an Expected Total Annual Earnings of ${currencyFormatter.format(baseSalary)}`
                          : undefined
                      }
                    ></CompensationSummaryTile>
                  </Grid>
                  <Grid size={{ xs: 3 }} container direction="column" spacing={2}>
                    <CompensationSummaryTile
                      label="Variable Compensation"
                      value={variablePercent}
                      formatter={twoDigitPercentFormatter}
                      width={'40%'}
                      border={true}
                      borderColor={footerBackgroundColor}
                      footer={`Up to ${currencyFormatter.format(
                        baseSalary * variableCap * variablePercent * periodProration
                      )} (Max)`}
                      footerTextColor={footerTextColor}
                      footerBackgroundColor={footerBackgroundColor}
                      backgroundColor={'transparent'}
                    ></CompensationSummaryTile>
                  </Grid>

                  <Grid size={{ xs: 3 }}>
                    <CompensationSummaryTile
                      label="On-Target Total Compensation"
                      value={
                        basePlus
                          ? baseSalary * periodProration + baseSalary * variablePercent * periodProration
                          : baseSalary * periodProration * (1 - variablePercent) +
                            baseSalary * variablePercent * periodProration
                      }
                      formatter={currencyFormatter}
                      width={'40%'}
                      border={true}
                      borderColor={footerBackgroundColor}
                      footer={`Up to ${currencyFormatter.format(
                        basePlus
                          ? baseSalary * periodProration + baseSalary * variableCap * variablePercent * periodProration
                          : baseSalary * periodProration * (1 - variablePercent) +
                              baseSalary * variableCap * variablePercent * periodProration
                      )} (Max)`}
                      footerTextColor={footerTextColor}
                      footerBackgroundColor={footerBackgroundColor}
                      backgroundColor={'transparent'}
                    ></CompensationSummaryTile>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
      </Stack>
    </React.Fragment>
  )
}

export default CompensationSummaryCard
