import { Theme } from '@mui/material/styles'

export default function LinearProgress(theme: Theme) {
  return {
    MuiLinearProgress: {
      defaultProps: {
        color: 'info',
      },
      variants: [],
      styleOverrides: {
        root: {
          height: 6,
          borderRadius: 100,
        },
        bar: {
          borderRadius: 100,
        },
      },
    },
  }
}
