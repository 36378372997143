import { Card as MuiCard, CardProps as MuiCardProps, SxProps } from '@mui/material'
import React, { FC } from 'react'

interface CardProps extends MuiCardProps {
  children: React.ReactNode
  sx?: SxProps
}

const Card: FC<CardProps> = ({ children, sx, ...rest }) => {
  return (
    <MuiCard
      sx={{
        borderRadius: '15px',
        ...sx,
      }}
      elevation={0}
      {...rest}
    >
      {children}
    </MuiCard>
  )
}

export default Card
