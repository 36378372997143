import { Box, Grid2 as Grid, InputLabel, Slider, Stack, Switch, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Paper from 'components/@extended/Paper'
import { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'

import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'

import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'
import CompensationChart from '../../components/CompensationChart'

const CompensationPlanDetailForm = () => {
  const theme = useTheme()
  const { compensationTemplate, plan, setPlan, employeeName, mode } = useCreatePlanContext()

  const [basePlus, setBasePlus] = useState<boolean>(plan.basePlus)
  const [displaySalary, setDisplaySalary] = useState<number>(
    plan.basePlus ? plan.baseSalary : plan.baseSalary / (1 - plan.variablePercent)
  )
  const [baseSalary, setBaseSalary] = useState<number>(plan.baseSalary)
  const [variablePercentage, setVariablePercentage] = useState<number>(plan.variablePercent)
  const [variableCompensation, setVariableCompensation] = useState<number>(0)
  const [totalCompensation, setTotalCompensation] = useState<number>(0)

  // Calculate initial variableCap as a currency value
  const [variableCap, setVariableCap] = useState<number>(plan.variableCap ?? 0)

  const getPeriodProration = (): number => {
    return plan.periodProration
  }

  const calculateCompensation = (basePlus: boolean, salary: number, variablePercentage: number) => {
    const periodProration = getPeriodProration()
    const bonusAmount = salary * variablePercentage
    const baseCompensation = basePlus ? salary : salary - bonusAmount
    const proratedBaseCompensation = baseCompensation * periodProration
    const proratedBonusAmount = basePlus
      ? proratedBaseCompensation * variablePercentage
      : salary * periodProration - proratedBaseCompensation

    return {
      baseCompensation: proratedBaseCompensation,
      variableCompensation: proratedBonusAmount,
      totalCompensation: proratedBaseCompensation + proratedBonusAmount,
    }
  }

  const updateCompensationValues = () => {
    const { baseCompensation, variableCompensation, totalCompensation } = calculateCompensation(
      basePlus,
      baseSalary,
      variablePercentage
    )
    setDisplaySalary(baseCompensation)
    setVariableCompensation(variableCompensation)
    setTotalCompensation(totalCompensation)
  }

  useEffect(() => {
    updateCompensationValues()
  }, [basePlus, baseSalary, variablePercentage, variableCap])

  useEffect(() => {
    updateCompensationValues()
  }, [])

  const onVariableIncludedChange = (value: boolean) => {
    setBasePlus(value)
    let tempPlan = { ...plan }
    tempPlan.basePlus = value
    setPlan(tempPlan)
  }

  const onVariablePercentageChange = (value: number) => {
    setVariablePercentage(value)
    let tempPlan = { ...plan }
    tempPlan.variablePercent = value
    setPlan(tempPlan)
  }

  const onSalaryChange = (floatValue: number) => {
    setBaseSalary(floatValue)
    let tempPlan = { ...plan }
    tempPlan.baseSalary = floatValue
    setPlan(tempPlan)
  }

  const onMaxVariableCompensationChange = (percentValue: number) => {
    let tempPlan = { ...plan }
    tempPlan.variableCap = percentValue
    setVariableCap(percentValue)
    setPlan(tempPlan)
  }

  const percentFormatter = new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container spacing={1} alignItems="center">
        <Grid size={{ xs: 12 }}>
          <Typography variant={'h4'}>Compensation Plan Details</Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <CompensationSummaryCard
            key="compensation-summary-card"
            name={employeeName}
            position={plan.position}
            effectiveDate={plan.effectiveDate}
            variableCap={plan.variableCap}
            period={compensationTemplate?.period}
            periodId={plan.periodId}
          />
        </Grid>

        <Grid size={{ xs: 12 }} sx={{ marginTop: 2 }}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, lg: 4 }}>
              <Stack spacing={4}>
                <NumericFormat
                  key="base-salary-input"
                  value={baseSalary}
                  prefix="$"
                  thousandSeparator
                  decimalScale={2}
                  customInput={TextField}
                  onValueChange={(values: any) => {
                    const { floatValue } = values
                    onSalaryChange(floatValue)
                  }}
                  isAllowed={(values: any) => {
                    const { floatValue } = values
                    return floatValue >= 0
                  }}
                  InputProps={{
                    readOnly: mode === 'REVIEW',
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '21px',
                    },
                  }}
                  variant="outlined"
                  label={basePlus ? 'Annual Base Salary' : 'Expected Total Annual Earnings'}
                  shrink
                />
                <NumericFormat
                  key="total-compensation-input"
                  value={totalCompensation}
                  prefix="$"
                  thousandSeparator
                  decimalScale={2}
                  InputProps={{
                    readOnly: true,
                  }}
                  customInput={TextField}
                  variant="outlined"
                  label="On Target Total Compensation"
                  shrink
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '21px',
                      '& .MuiOutlinedInput-input': {
                        backgroundColor: theme.palette.secondary.lighter,
                        borderRadius: '21px',
                      },
                    },
                  }}
                />
                <NumericFormat
                  key="base-compensation-input"
                  value={displaySalary}
                  prefix="$"
                  thousandSeparator
                  decimalScale={2}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '21px',
                      '& .MuiOutlinedInput-input': {
                        backgroundColor: theme.palette.secondary.lighter,
                        borderRadius: '21px',
                      },
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  customInput={TextField}
                  variant="outlined"
                  label="Base Compensation"
                  shrink
                />
                <NumericFormat
                  key="variable-compensation-input"
                  value={variableCompensation}
                  prefix="$"
                  thousandSeparator
                  decimalScale={2}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '21px',
                      '& .MuiOutlinedInput-input': {
                        backgroundColor: theme.palette.secondary.lighter,
                        borderRadius: '21px',
                      },
                    },
                  }}
                  customInput={TextField}
                  variant="outlined"
                  label="Variable Compensation"
                  shrink
                />
              </Stack>
            </Grid>

            <Grid size={{ xs: 12, lg: 4 }}>
              <Stack spacing={4}>
                <Box sx={{ mb: 2, mt: 2 }}>
                  <Stack direction="row" spacing={2}>
                    <Typography>Variable Included</Typography>
                    <Switch
                      key="variable-included-switch"
                      disabled={mode === 'REVIEW'}
                      checked={basePlus}
                      onChange={(event) => {
                        onVariableIncludedChange(event.target.checked)
                      }}
                    />
                    <Typography>Base Plus</Typography>
                  </Stack>
                </Box>
                <Stack spacing={1}>
                  <InputLabel id="bonus-percentage-label">Variable Compensation Percentage</InputLabel>
                  <Box sx={{ pl: 2, pr: 2 }}>
                    <Slider
                      key="variable-percentage-slider"
                      disabled={mode === 'REVIEW'}
                      onChange={(event, value) => {
                        onVariablePercentageChange((value as number) / 100)
                      }}
                      aria-label="Always visible"
                      value={variablePercentage * 100}
                      min={(compensationTemplate?.template.minVariablePercent ?? 0) * 100 || 0}
                      max={(compensationTemplate?.template.maxVariablePercent ?? 0) * 100 || 100}
                      step={0.5}
                      valueLabelDisplay="on"
                      valueLabelFormat={(value) => {
                        const formattedValue = new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format((baseSalary * (value as number)) / 100)
                        return formattedValue
                      }}
                      marks={[
                        {
                          value: (compensationTemplate?.template.minVariablePercent ?? 0) * 100,
                          label: percentFormatter.format(compensationTemplate?.template.minVariablePercent ?? 0),
                        },
                        {
                          value: (compensationTemplate?.template.maxVariablePercent ?? 0) * 100,
                          label: percentFormatter.format(compensationTemplate?.template.maxVariablePercent ?? 0),
                        },
                        {
                          value: variablePercentage * 100,
                          label: `${(variablePercentage * 100).toFixed(1)}%`,
                        },
                      ]}
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <InputLabel id="employee-max-variable-comp-label">Maximum Payout</InputLabel>
                  <Box sx={{ pl: 2, pr: 2 }}>
                    <Slider
                      key={`variable-cap-slider`}
                      disabled={mode === 'REVIEW'}
                      onChange={(event, value) => {
                        const newVariableCapPercentage = (value as number) / 100 // Convert to decimal percentage
                        onMaxVariableCompensationChange(newVariableCapPercentage) // Pass the percentage in decimal form
                      }}
                      sx={{ mt: 2.5 }}
                      aria-label="Always visible"
                      value={(variableCap ?? 0) * 100} // Convert decimal percentage to whole number for the slider
                      min={(compensationTemplate?.template.minVariableCap ?? 0) * 100}
                      max={(compensationTemplate?.template.maxVariableCap ?? 0) * 100}
                      step={0.5}
                      valueLabelDisplay="on"
                      valueLabelFormat={(value) => {
                        const formattedValue = new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format((baseSalary * (value as number) * variablePercentage) / 100)
                        return formattedValue
                      }}
                      marks={[
                        {
                          value: (compensationTemplate?.template.minVariableCap ?? 0) * 100,
                          label: `${((compensationTemplate?.template.minVariableCap ?? 0) * 100).toFixed(1)}%`,
                        },
                        {
                          value: (compensationTemplate?.template.maxVariableCap ?? 0) * 100,
                          label: `${((compensationTemplate?.template.maxVariableCap ?? 0) * 100).toFixed(1)}%`,
                        },
                        {
                          value: variableCap * 100,
                          label: `${(variableCap * 100).toFixed(1)}%`,
                        },
                      ]}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Grid>

            <Grid size={{ xs: 12, lg: 4 }} alignItems={'start'}>
              <Stack spacing={1}>
                <CompensationChart key="compensation-chart" salary={displaySalary} variable={variableCompensation} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CompensationPlanDetailForm
