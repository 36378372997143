import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AnimateButton from 'components/@extended/AnimateButton'
import WarningDialog from 'components/WarningDialog'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import React, { useState } from 'react'
import {
  checkDateAgainstExistingPeriods,
  generatePeriods,
  hasPeriodOverlapAgainstExistingPeriods,
  PeriodLevels,
} from 'services/periodServices'
import { Period } from 'types/periods'
import PeriodsTable from '../../../../components/PeriodsTable'

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

interface PeriodsWizardProp {
  rootOrganizationID: string | null
  existingPeriods: Period[]
  refetchPeriods: () => void
}

const PeriodsWizard: React.FC<PeriodsWizardProp> = ({ rootOrganizationID, existingPeriods, refetchPeriods }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [step, setStep] = useState<number>(1)
  const [length, setLength] = useState<number>(1)
  const [depth, setDepth] = useState<string>('MONTH')
  const [generatedPeriods, setGeneratedPeriods] = useState<Period[]>([])
  const [yearType, setYearType] = useState<string>('CALENDAR')
  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(null)
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false)
  const [warningDialogMessage, setWarningDialogMessage] = useState<string>('')
  const { token } = useAuth()

  const handleOpen = () => setOpen(true)

  const handleClose = () => {
    setOpen(false)
    setStep(1)
  }

  const handleNext = () => {
    if (step === 1) {
      // Generate periods based on length and depth
      if (!checkDateAgainstExistingPeriods(existingPeriods, selectedDate!)) {
        const newlyGeneratedPeriods: Period[] = []
        if (rootOrganizationID) {
          newlyGeneratedPeriods.push(
            ...generatePeriods(rootOrganizationID!, new Date(selectedDate!.toDate()), PeriodLevels.MONTH, length)
          )
          setGeneratedPeriods(newlyGeneratedPeriods)
          if (hasPeriodOverlapAgainstExistingPeriods(existingPeriods, newlyGeneratedPeriods)) {
            setWarningDialogMessage('There is an overlap in generated periods.')
            setOpenWarningDialog(true)
          } else {
            setStep(2)
          }
        } else {
          console.log('WARNING: Root Org is not set')
        }
      } else {
        setWarningDialogMessage('The selected start date is in existing periods.')
        setOpenWarningDialog(true)
      }
    } else {
      // Handle confirmation

      // Loop through generated periods and print each to console
      generatedPeriods.forEach((period, index) => {
        postWithToken('periods', token!, period)
          .then(() => {
            console.log('Periods Created')
            setOpen(false)
          })
          .catch((error) => {
            console.log('Periods Creation Failed')
            setWarningDialogMessage('Failed to create component')
            setOpenWarningDialog(true)
          })
      })

      refetchPeriods()
      handleClose()
    }
  }

  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    console.log('Date selection changed')
    let updatedDate: dayjs.Dayjs | null = newValue
    // Make sure the selected date and settings do not fall into the existing periods.
    // This is a simple check when selecting the date. Note this does not insure
    // generated periods do not conflict. It just checks the start date.
    if (newValue) {
      updatedDate = newValue.date(1) // selected value should always be 1st day of month
      if (yearType === 'CALENDAR') {
        // If setting a calendar year period it should start on 1st day of the 1st month
        console.log('setting month')
        updatedDate = updatedDate.month(0)
      }
    }
    if (!checkDateAgainstExistingPeriods(existingPeriods, updatedDate!)) {
      console.log('New Value: ', updatedDate)
      setSelectedDate(updatedDate)
    } else {
      // Show a warning dialog for date conflict
      setWarningDialogMessage('The selected date has a conflict with existing periods.')
      setOpenWarningDialog(true)
    }
    console.log('completed date selection change')
  }

  return (
    <div>
      <Button variant="contained" sx={{ mb: 2 }} onClick={handleOpen} disabled={!rootOrganizationID}>
        Open Periods Creation Wizard
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiPaper-root': {
            maxHeight: '500px',
            maxWidth: '500px',
          },
        }}
      >
        <DialogTitle>{step === 1 ? 'Step 1: Select Parameters' : 'Step 2: Confirm Periods'}</DialogTitle>
        <DialogContent
          sx={{
            display: step === 1 ? 'flex' : 'block',
            alignItems: step === 1 ? 'center' : 'initial',
            overflow: 'hidden',
          }}
        >
          {step === 1 ? (
            <Grid
              container
              spacing={4}
              sx={{
                px: 3,
              }}
            >
              {/* Year Type Selection */}
              <Grid size={{ xs: 6 }}>
                <Typography variant="h5" color="primary">
                  What type of year do you want to create?
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Choose between a calendar year (Jan-Dec) or a custom fiscal year
                </Typography>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <Select
                  fullWidth
                  variant="outlined"
                  value={yearType}
                  onChange={(e) => setYearType(e.target.value as string)}
                >
                  <MenuItem value="CALENDAR">Calendar Year</MenuItem>
                  <MenuItem value="FISCAL">Custom Fiscal Year</MenuItem>
                </Select>
              </Grid>

              {yearType === 'CALENDAR' ? (
                <>
                  {/* Start Date Selection */}
                  <Grid size={{ xs: 6 }}>
                    <Typography variant="h5" color="primary">
                      When do you want to start?
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Select the starting year for your periods
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Select Starting Year"
                        views={['year']}
                        value={selectedDate}
                        onChange={handleDateChange}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              ) : (
                <>
                  {/* Fiscal Year Start Selection */}
                  <Grid size={{ xs: 6 }}>
                    <Typography variant="h5" color="primary">
                      When does your fiscal year start?
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Select the starting month and year
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        views={['year', 'month']}
                        value={selectedDate}
                        onChange={handleDateChange}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            fullWidth: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              )}

              {/* Length Selection */}
              <Grid size={{ xs: 6 }}>
                <Typography variant="h5" color="primary">
                  {yearType === 'CALENDAR' ? 'How many years do you need?' : 'How many fiscal years do you need?'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Choose how many years to generate
                </Typography>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <Select
                  fullWidth
                  variant="outlined"
                  value={length}
                  onChange={(e) => setLength(e.target.value as number)}
                >
                  {[1, 2, 3, 4].map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {/* Depth Selection */}
              <Grid size={{ xs: 6 }}>
                <Typography variant="h5" color="primary">
                  How detailed should the periods be?
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Select the breakdown of periods within each {yearType === 'CALENDAR' ? 'year' : 'fiscal year'}
                </Typography>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <Select fullWidth variant="outlined" value={depth} onChange={(e) => setDepth(e.target.value as string)}>
                  <MenuItem value="MONTH">Month</MenuItem>
                  <MenuItem value="QUARTER">Quarter</MenuItem>
                  <MenuItem value="BIANNUAL">Bi-Annual</MenuItem>
                  <MenuItem value="ANNUAL">Annual</MenuItem>
                </Select>
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                mt: 2,
                height: '100%',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                p: 1,
              }}
            >
              <PeriodsTable periods={generatedPeriods} maxHeight="500px" />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', px: 3, pb: 3 }}>
          <AnimateButton>
            <Button variant="contained" color="warning" onClick={handleClose}>
              Cancel
            </Button>
          </AnimateButton>

          <Tooltip title={step === 1 ? 'Next Step' : 'Create Periods'} arrow placement="top-start">
            <span>
              <AnimateButton>
                <Button variant="contained" color="primary" onClick={handleNext} disabled={!selectedDate}>
                  {step === 1 ? 'Next' : 'Create Periods'}
                </Button>
              </AnimateButton>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>

      <WarningDialog
        open={openWarningDialog}
        onClose={() => {
          setOpenWarningDialog(false)
        }}
        displayMessage={warningDialogMessage}
      />
    </div>
  )
}

export default PeriodsWizard
