import React from 'react'
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import Paper from 'components/@extended/Paper'
import { WizardConfiguration } from 'types/wizard-config'
import ComponentField from 'components/ComponentField'

interface TargetStepProps {
  selectedConfiguration: WizardConfiguration | null
  setSelectedConfiguration: (configuration: WizardConfiguration) => void
  rootPeriodLabel: string
}

const TargetStep: React.FC<TargetStepProps> = ({
  selectedConfiguration,
  setSelectedConfiguration,
  rootPeriodLabel,
}) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        gap: 2,
      }}
    >
      <div>
        <Box display="flex" flexDirection="column" justifyContent="space-between" gap={2}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
            <Typography variant="h5">Confirm Targets for Creation</Typography>
            <Typography variant="h5">Current Active Period: {rootPeriodLabel}</Typography>
          </Box>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '200px' }}>Component Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell style={{ width: '150px' }}>{rootPeriodLabel} Target</TableCell>
                  <TableCell style={{ width: '150px' }}>Minimum Target</TableCell>
                  <TableCell style={{ width: '150px' }}>Maximum Target</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedConfiguration?.targets.map((target) => {
                  return (
                    <TableRow key={target.id}>
                      <TableCell>{target?.name}</TableCell>
                      <TableCell>{target?.description}</TableCell>
                      <TableCell>
                        <ComponentField
                          value={target?.value ?? 0}
                          componentType={target?.format}
                          onChange={(newValue) => {
                            console.log('New root target:', newValue)
                            const updatedComponents = selectedConfiguration?.targets.map((c) => {
                              if (c.id === target.id) {
                                return { ...c, value: newValue }
                              }
                              return c
                            })
                            setSelectedConfiguration({ ...selectedConfiguration, targets: updatedComponents })
                          }}
                          name={`targetRoot-${target?.id}`}
                        />
                      </TableCell>
                      <TableCell>
                        <ComponentField
                          value={target?.minTargetValue ?? 0}
                          componentType={target?.format}
                          onChange={(newValue) => {
                            console.log('New targetMin:', newValue)
                            const updatedComponents = selectedConfiguration?.targets.map((c) => {
                              if (c.id === target.id) {
                                return { ...c, minTargetValue: newValue }
                              }
                              return c
                            })
                            setSelectedConfiguration({ ...selectedConfiguration, targets: updatedComponents })
                          }}
                          name={`targetMin-${target?.id}`}
                        />
                      </TableCell>
                      <TableCell>
                        <ComponentField
                          value={target?.maxTargetValue ?? 0}
                          componentType={target?.format}
                          onChange={(newValue) => {
                            console.log('New targetMax:', newValue)
                            const updatedComponents = selectedConfiguration?.targets.map((c) => {
                              if (c.id === target.id) {
                                return { ...c, maxTargetValue: newValue }
                              }
                              return c
                            })
                            setSelectedConfiguration({ ...selectedConfiguration, targets: updatedComponents })
                          }}
                          name={`targetMax-${target?.id}`}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </div>
    </Paper>
  )
}

export default TargetStep
