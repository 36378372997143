// import { TextField } from 'components/@extended/TextField'
import { TextField } from 'components/@extended/TextField'
import { TextFieldVariants } from '@mui/material'
import React from 'react'
import { NumericFormat } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  pill?: boolean
  shrink?: boolean
}

const NumericFormatCustom = React.forwardRef<HTMLInputElement, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, pill = false, shrink = false, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: string }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
      decimalScale={0}
      allowNegative={false}
      pill={pill}
      shrink={shrink}
    />
  )
})

interface WholeNumberFieldProps {
  id?: string
  label?: string
  value: string
  onChange: (value: string) => void
  name: string
  style?: React.CSSProperties // Add the optional style property
  variant?: TextFieldVariants
  pill?: boolean
  shrink?: boolean
}

const WholeNumberField: React.FC<WholeNumberFieldProps> = ({
  id,
  label,
  value,
  onChange,
  name,
  style, // Destructure the style prop
  variant = 'standard',
  pill = false,
  shrink = false,
}) => {
  return (
    <TextField
      id={id}
      fullWidth
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      name={name}
      slotProps={{
        input: {
          inputComponent: NumericFormatCustom as any,
        },
      }}
      style={style} // Pass the style prop
      variant={variant}
      pill={pill}
      shrink={shrink}
    />
  )
}

export default WholeNumberField
