import { Box, Divider, Grid2 as Grid, Typography, styled } from '@mui/material'
import Paper from 'components/@extended/Paper'
import React from 'react'

interface AccrualDetailsProps {
  accrual: any // Replace 'any' with the actual type of your accrual object
  organizationLabels: Record<string, string>
  periodLabels: Record<string, string>
  ledgerAccountLabels: Record<string, string>
}

const LabelTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}))

const AccrualDetails: React.FC<AccrualDetailsProps> = ({
  accrual,
  organizationLabels,
  periodLabels,
  ledgerAccountLabels,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            {`${accrual.userFirstName} ${accrual.userLastName}`.trim()}
          </Typography>
          <Typography variant="subtitle1" gutterBottom color="text.secondary">
            {`${accrual.allocationTableName} | ${accrual.allocationRowName}`}
          </Typography>
        </Box>
      </Grid>
      <Grid size={{ xs: 3 }}>
        <LabelTypography variant="subtitle2">Team</LabelTypography>
        <Typography>{organizationLabels[accrual.organizationId] || accrual.organizationId}</Typography>
      </Grid>
      <Grid size={{ xs: 3 }}>
        <LabelTypography variant="subtitle2">Period</LabelTypography>
        <Typography>{periodLabels[accrual.periodId] || accrual.periodId}</Typography>
      </Grid>
      <Grid size={{ xs: 3 }}>
        <LabelTypography variant="subtitle1">Status</LabelTypography>
        <Typography>{accrual.status.charAt(0).toUpperCase() + accrual.status.slice(1)}</Typography>
      </Grid>
      <Grid size={{ xs: 3 }}>
        <LabelTypography variant="subtitle1">Account</LabelTypography>
        <Typography>{ledgerAccountLabels[accrual.userLedgerAccountId] || accrual.userLedgerAccountId}</Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Paper elevation={0} sx={{ border: '1px solid', borderColor: 'divider' }}>
          <Box p={2}>
            <Grid container>
              <Grid size={{ xs: 5 }}>
                <LabelTypography variant="subtitle2">Input</LabelTypography>
              </Grid>
              <Grid size={{ xs: 3 }}>
                <LabelTypography variant="subtitle2">Value</LabelTypography>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <LabelTypography variant="subtitle2">Approval Date</LabelTypography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Divider sx={{ my: 1 }} />
              </Grid>
              <Grid size={{ xs: 5 }}>
                <Typography>Annual Revenue Growth Rate Target</Typography>
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Typography>6%</Typography>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <Typography>2023-05-15</Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Divider sx={{ my: 1 }} />
              </Grid>
              <Grid size={{ xs: 5 }}>
                <Typography>Annual Revenue Growth Rate Metric</Typography>
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Typography>7.2%</Typography>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <Typography>2023-05-20</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AccrualDetails
