import { useTheme } from '@mui/material/styles'
import { ApexOptions } from 'apexcharts'
import { FC, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { ThemeMode } from 'types/config'

interface VariableCompensationChartProps {
  variableEarned?: number
  variableTotal?: number
  currency?: string
  currencyDecimals?: number
  showRemaining?: boolean
}

const VariableCompensationChart: FC<VariableCompensationChartProps> = ({
  variableEarned = 0,
  variableTotal = 0,
  currency = 'USD',
  currencyDecimals = 2,
  showRemaining = true,
}) => {
  const theme = useTheme()
  const [chartOptions] = useState<ApexOptions>({
    chart: {
      type: 'donut',
      animations: {
        enabled: true,
      },
    },
    colors:
      theme.palette.mode === ThemeMode.LIGHT
        ? [theme.palette.warning.main, theme.palette.grey[200]]
        : [theme.palette.warning.main, theme.palette.grey[700]],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              color: theme.palette.text.primary,
              fontSize: '1rem',
              fontWeight: 'bold',
              showAlways: true,
              show: true,
              formatter: function (w) {
                return formatCurrency(variableTotal, currency, currencyDecimals)
              },
            },
            value: {
              color: theme.palette.text.primary,
              fontSize: '1rem',
              show: true,
            },
            name: {
              color: theme.palette.text.primary,
              fontSize: '1rem',
              show: true,
            },
          },
        },
      },
    },
    legend: {
      position: 'bottom',
      fontSize: '14px',
      labels: {
        colors: theme.palette.text.primary,
      },
      formatter: function (val, opts) {
        if (val === 'Remaining') {
          return ''
        }
        const seriesValue = opts?.w?.globals?.series?.[opts.seriesIndex]
        return `${val} - ${formatCurrency(seriesValue, currency, currencyDecimals)}`
      },
      markers: {
        fillColors: [theme.palette.warning.main],
        size: 8,
        offsetX: -1000,
        customHTML: function (this: { seriesIndex: number }) {
          return ['<span></span>', ''][this.seriesIndex]
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return formatCurrency(val, currency, currencyDecimals)
        },
      },
    },
    labels: ['Earned', 'Remaining'],
  })

  const formatCurrency = (value: number, cur: string, dec: number) => {
    if (typeof value !== 'number' || isNaN(value)) return `${cur} 0`

    try {
      return new Intl.NumberFormat('default', {
        style: 'currency',
        currency: cur,
        minimumFractionDigits: dec,
        maximumFractionDigits: dec,
      }).format(value)
    } catch (error) {
      console.error('Error formatting currency:', error)
      return `${cur} ${value.toFixed(dec)}`
    }
  }

  const series = [variableEarned || 0, (variableTotal || 0) - (variableEarned || 0)]

  return (
    <>
      {variableTotal ? (
        <ReactApexChart
          key={`apex-variable-chart-${series.join('-')}`}
          options={chartOptions}
          series={series}
          type="donut"
        />
      ) : (
        <div>No data available for the chart</div>
      )}
    </>
  )
}

export default VariableCompensationChart
