import React, { FC, useState, Suspense } from 'react'
import { Box, Button, CircularProgress, Stepper, Step, StepLabel } from '@mui/material'
import GeneralStep from './GeneralStep'
import PeriodStep from './PeriodStep'
import TargetStep from './TargetStep'
import TemplateStep from './TemplateStep'
import useLocalStorage from 'hooks/useLocalStorage'
import SimpleTitle from 'components/SimpleTitle'
import { PeriodConfiguration, WizardConfiguration } from 'types/wizard-config'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { PurcentAppState } from 'types/purcent-app-state'
import { wizardConfigData as defaultWizardConfigData } from 'utils/jumpStartWizardData'
import dayjs from 'dayjs'
import { usePeriods } from 'hooks/usePeriods'
import useAuth from 'hooks/useAuth'
import SuccessStep from './SuccessStep'
import { useNavigate } from 'react-router-dom'
import { getCurrentRootPeriod } from 'services/periodServices'

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL

const steps = ['General', 'Periods', 'Targets', 'Templates', 'Completed']

const JumpStartWizard: React.FC = () => {
  const { purcentAppState } = useGlobalState()
  const { token } = useAuth()

  const [wizardConfiguration, setWizardConfiguration] = useState<WizardConfiguration[]>(defaultWizardConfigData)

  const [selectedConfiguration, setSelectedConfiguration] = useLocalStorage<WizardConfiguration | null>(
    'jumpstart_selected_configuration',
    wizardConfiguration.length > 0 ? wizardConfiguration[0] : null
  )
  const [periodConfiguration, setPeriodConfiguration] = useLocalStorage<PeriodConfiguration>(
    'jumpstart_period_configuration',
    {
      rootOrganizationId: purcentAppState?.selectedOrganization?.rootOrganizationId || '',
      type: 'CALENDAR',
      startDate: dayjs().startOf('year').format('DD-MM-YYYY'),
      length: 3,
      depth: 'MONTH',
    }
  )

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <JumpStartWizardContent
        token={token!}
        purcentAppState={purcentAppState!}
        wizardConfiguration={wizardConfiguration}
        setWizardConfiguration={setWizardConfiguration}
        selectedConfiguration={selectedConfiguration}
        setSelectedConfiguration={setSelectedConfiguration}
        periodConfiguration={periodConfiguration}
        setPeriodConfiguration={setPeriodConfiguration}
      />
    </Suspense>
  )
}

const JumpStartWizardContent: FC<{
  token: string
  purcentAppState: PurcentAppState
  wizardConfiguration: WizardConfiguration[]
  setWizardConfiguration: (configuration: WizardConfiguration[]) => void
  selectedConfiguration: WizardConfiguration | null
  setSelectedConfiguration: (configuration: WizardConfiguration | null) => void
  periodConfiguration: PeriodConfiguration
  setPeriodConfiguration: (configuration: PeriodConfiguration) => void
}> = ({
  token,
  purcentAppState,
  wizardConfiguration,
  setWizardConfiguration,
  selectedConfiguration,
  setSelectedConfiguration,
  periodConfiguration,
  setPeriodConfiguration,
}) => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useLocalStorage<number>('jumpstart_wizard_active_step', 0)
  const { periods: existingPeriods, isLoadingPeriods } = usePeriods(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || '',
    !!token
  )
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const handleConfigurationSelectionChange = (newConfiguration: WizardConfiguration | null) => {
    setSelectedConfiguration(newConfiguration)
    console.log(newConfiguration)
    if (!isLoadingPeriods) {
      console.log(existingPeriods)
      console.log(purcentAppState?.selectedOrganization?.rootOrganizationId)
    }
  }

  const handleCompleteSetup = async () => {
    setIsProcessing(true)
    // TODO: call API to create all the periods, targets, and templates
    if (!token || !purcentAppState?.selectedOrganization?.rootOrganizationId) {
      console.error('No token or root organization ID found')
      setIsSuccess(false)
      setIsProcessing(false)
      return
    }
    console.log(JSON.stringify(selectedConfiguration))
    const url = `${apiServerUrl}wizard/${purcentAppState?.selectedOrganization?.rootOrganizationId}`
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(selectedConfiguration),
      })

      if (!response.ok) {
        setIsSuccess(false)
        setIsProcessing(false)
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log(data)
      setIsSuccess(true)
      setIsProcessing(false)
      return
    } catch (error) {
      console.error('Error during complete setup:', error)
      setIsSuccess(false)
      setIsProcessing(false)
    }
  }

  const handleNext = () => {
    if (activeStep === steps.length - 2) {
      setIsProcessing(true)
      // TODO: call API to create all the periods, targets, and templates
      console.log('Creating periods, targets, and templates')
      handleCompleteSetup()
      setIsSuccess(true)
      setIsProcessing(false)
    }
    setWizardConfiguration(wizardConfiguration)
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1)
  }

  const getStepContent = (step: number) => {
    if (isLoadingPeriods || !existingPeriods) {
      return <LoadingSpinner />
    }
    switch (step) {
      case 0:
        return (
          <GeneralStep
            wizardConfiguration={wizardConfiguration}
            selectedBusinessCategory={selectedConfiguration}
            setBusinessCategory={handleConfigurationSelectionChange}
            periodConfiguration={periodConfiguration}
            setPeriodConfiguration={setPeriodConfiguration}
            existingPeriods={existingPeriods.periods}
          />
        )
      case 1:
        return (
          <PeriodStep
            periodConfiguration={periodConfiguration}
            selectedConfiguration={selectedConfiguration}
            setSelectedConfiguration={setSelectedConfiguration}
            existingPeriods={existingPeriods.periods}
          />
        )
      case 2:
        return (
          <TargetStep
            selectedConfiguration={selectedConfiguration}
            setSelectedConfiguration={setSelectedConfiguration}
            rootPeriodLabel={getCurrentRootPeriod(selectedConfiguration?.periods || [])?.label || 'Period'}
          />
        )
      case 3:
        return <TemplateStep selectedConfiguration={selectedConfiguration} />
      case 4:
        return <SuccessStep isSuccess={isSuccess} isProcessing={isProcessing} />
      default:
        return 'Unknown step'
    }
  }

  return (
    <>
      {isLoadingPeriods ? (
        <LoadingSpinner />
      ) : (
        <div>
          <Box>
            <SimpleTitle title={`Jump Start Wizard`} />
            <Box
              sx={{
                height: '80%',
                width: '90%',
                p: 4,
              }}
            >
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {}
                  const labelProps: { optional?: React.ReactNode } = {}
                  return (
                    <Step key={label} {...stepProps} onClick={() => setActiveStep(index)} style={{ cursor: 'pointer' }}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>

              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    gap: 2,
                  }}
                >
                  {isLoadingPeriods ? <LoadingSpinner /> : getStepContent(activeStep)}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, px: 2 }}>
                  <Button variant="contained" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep === steps.length - 1 ? (
                    <>
                      <Button onClick={() => navigate('/organization')}>Add Team Members</Button>
                      <Button onClick={() => navigate('/admin/templates')}>Create Plan from Template</Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={activeStep === 0 && !selectedConfiguration}
                    >
                      {activeStep === steps.length - 2 ? 'Complete Setup' : 'Next'}
                    </Button>
                  )}
                </Box>
              </>
            </Box>
          </Box>
        </div>
      )}
    </>
  )
}

const LoadingSpinner: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <CircularProgress />
  </Box>
)

export default JumpStartWizard
