import { Box, TextField } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { ComponentType } from 'types/components'
import { formatter } from 'utils/formatting'

interface NumericFieldProps {
  componentFormat?: ComponentType | null
  value: number
  minValue?: number
  maxValue?: number
  readOnly?: boolean
  label?: string
  onValueChange?: (value: number) => void
  variant?: 'standard' | 'outlined' | 'filled'
  pill?: boolean
  shrink?: boolean
}

export function NumericField({
  componentFormat = ComponentType.COMPONENT_TYPE_CURRENCY,
  value,
  minValue,
  maxValue,
  readOnly = false,
  label,
  onValueChange,
  variant = 'standard',
  pill = false,
  shrink = false,
}: NumericFieldProps) {
  const getHelperText = (
    minValue: number | undefined,
    maxValue: number | undefined,
    value: number,
    format: ComponentType | null
  ) => {
    if (minValue !== undefined && maxValue === undefined && value < minValue) {
      return { helperText: `Value must be greater than ${formatter(minValue, componentFormat)}` }
    }
    if (minValue === undefined && maxValue !== undefined && value > maxValue) {
      return { helperText: `Value must be less than ${formatter(maxValue, componentFormat)}` }
    }
    if (minValue !== undefined && maxValue !== undefined) {
      return {
        helperText: `Allowed range ${formatter(minValue, componentFormat)} - ${formatter(maxValue, componentFormat)}`,
      }
    }
    return {}
  }

  return (
    <Box display="flex">
      {componentFormat === ComponentType.COMPONENT_TYPE_PERCENTAGE ? (
        <NumericFormat
          label={label}
          value={value * 100}
          suffix="%"
          decimalScale={2}
          customInput={TextField}
          onValueChange={(values: any) => {
            const { floatValue } = values
            onValueChange!(floatValue / 100)
          }}
          {...getHelperText(minValue, maxValue, value, componentFormat)}
          {...(readOnly ? { InputProps: { readOnly: true } } : {})}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: pill ? '21px' : '0px',
            },
          }}
          variant={variant}
          shrink={{ shrink }}
        />
      ) : componentFormat === ComponentType.COMPONENT_TYPE_CURRENCY ? (
        <NumericFormat
          label={label}
          value={value}
          prefix="$"
          decimalScale={2}
          thousandSeparator={true}
          customInput={TextField}
          onValueChange={onValueChange}
          {...getHelperText(minValue, maxValue, value, componentFormat)}
          {...(readOnly ? { InputProps: { readOnly: true } } : {})}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: pill ? '21px' : '0px',
            },
          }}
          variant={variant}
          shrink={{ shrink }}
        />
      ) : componentFormat === ComponentType.COMPONENT_TYPE_INTEGER ? (
        <NumericFormat
          label={label}
          value={value}
          decimalScale={0}
          customInput={TextField}
          onValueChange={onValueChange}
          {...getHelperText(minValue, maxValue, value, componentFormat)}
          {...(readOnly ? { InputProps: { readOnly: true } } : {})}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: pill ? '21px' : '0px',
            },
          }}
          variant={variant}
          shrink={{ shrink }}
        />
      ) : componentFormat === ComponentType.COMPONENT_TYPE_FLOAT ? (
        <NumericFormat
          label={label}
          value={value}
          decimalScale={2}
          customInput={TextField}
          onValueChange={onValueChange}
          {...getHelperText(minValue, maxValue, value, componentFormat)}
          {...(readOnly ? { InputProps: { readOnly: true } } : {})}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: pill ? '21px' : '0px',
            },
          }}
          variant={variant}
          shrink={{ shrink }}
        />
      ) : (
        <NumericFormat
          label={label}
          value={value}
          prefix="$"
          decimalScale={2}
          thousandSeparator={true}
          customInput={TextField}
          onValueChange={onValueChange}
          {...getHelperText(minValue, maxValue, value, componentFormat)}
          {...(readOnly ? { InputProps: { readOnly: true } } : {})}
          sx={{
            '& .MuiInputBase-root': {
              borderRadius: pill ? '21px' : '0px',
            },
          }}
          variant={variant}
          shrink={{ shrink }}
        />
      )}
    </Box>
  )
}
