import { Box, CardContent, Typography, useTheme } from '@mui/material'
import Card from 'components/@extended/Card'
import CardFooter from 'components/cards/CardFooter'
import { FC } from 'react'

interface TeamOverviewProps {
  userPeriodOverviews: any[]
}

const TeamOverview: FC<TeamOverviewProps> = ({ userPeriodOverviews }) => {
  const theme = useTheme()

  const calculateTotals = () => {
    let totalOnTargetAmount = 0
    let totalPayoutAmount = 0

    if (userPeriodOverviews) {
      userPeriodOverviews.forEach((user) => {
        user.planItems.forEach((item: any) => {
          totalOnTargetAmount += item.onTargetAmount
          totalPayoutAmount += item.payoutAmount
        })
      })
    }

    return { totalOnTargetAmount, totalPayoutAmount }
  }

  const { totalOnTargetAmount, totalPayoutAmount } = calculateTotals()

  const formattedTotalPayout = totalPayoutAmount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const formattedTotalOnTarget = totalOnTargetAmount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const totalColor = theme.palette.grey[400]

  return (
    <Card variant="outlined">
      <CardContent>
        <Box sx={{ mb: 1, ml: 3 }}>
          <Typography sx={{ fontWeight: '500', fontSize: 18, mt: 1 }}>Total Variable Compensation Earned:</Typography>
          <Typography variant="h2" sx={{ fontWeight: '700', mt: 1 }}>
            ${formattedTotalPayout}
          </Typography>
        </Box>
      </CardContent>
      <CardFooter>
        <Box sx={{ ml: 5 }}>
          <Typography sx={{ color: totalColor, fontWeight: 'bold', fontSize: 14 }}>
            {`Up to $${formattedTotalOnTarget} (On-Target Variable Compensation)`}
          </Typography>
        </Box>
      </CardFooter>
    </Card>
  )
}

export default TeamOverview
