import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { CardActions, CardContent, Grid2 as Grid, IconButton, Stack, Theme, Typography, useTheme } from '@mui/material'
import AcceleratorSelector from 'components/AcceleratorSelector'
import Paper from 'components/@extended/Paper'
import FormulaEditor from 'components/FormulaEditor'
import React from 'react'
import { VariableAllocationRow, VariableAllocationTable } from 'types/comp-allocations'
import { ExpandedCompensationPlan, getSlugLabelMapForPlan } from 'types/comp-plan'
import { ExpandedTarget } from 'types/components'
import { LedgerAccount } from 'types/ledger-account'

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
}

const AllocationTable: React.FC<{
  data: VariableAllocationTable
  tableIndex: number
  periodProration: number
  theme: Theme
  baseSalary: number
  variablePercent: number
  slugLabelMap: { [key: string]: string }
  ledgerAccounts: LedgerAccount[] | null
  selectedTargets: ExpandedTarget[] | null
}> = ({
  data,
  tableIndex,
  periodProration,
  theme,
  baseSalary,
  variablePercent,
  slugLabelMap,
  ledgerAccounts,
  selectedTargets,
}) => {
  // Calculate the total payout for visible rows
  const totalPayout = data.rows.reduce((acc, row) => {
    const payoutValue = baseSalary * row.allocationPercentage * variablePercent * periodProration
    return acc + payoutValue
  }, 0)

  return (
    <Paper sx={{ marginBottom: 3 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid size={{ xs: 4 }}>
            <Typography variant="h3">{data.label}</Typography>
          </Grid>
          <Grid size={{ xs: 2 }} style={{ paddingRight: '30px' }}>
            <Typography variant="h5" align="center">
              Allocation %
            </Typography>
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Typography variant="h5" align="center">
              Max Payout
            </Typography>
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Typography variant="h5" align="center">
              Accelerator
            </Typography>
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Typography variant="h5" align="center">
              On-Target Payout
            </Typography>
          </Grid>
        </Grid>
        {data.rows.map((row, index) => (
          <AllocationRow
            key={index}
            row={row}
            tableIndex={tableIndex}
            rowIndex={index}
            baseSalary={baseSalary}
            variablePercent={variablePercent}
            periodProration={periodProration}
            theme={theme}
            slugLabelMap={slugLabelMap}
            ledgerAccounts={ledgerAccounts}
            selectedTargets={selectedTargets}
          />
        ))}
      </CardContent>
      <CardActions
        style={{
          justifyContent: 'flex-end',
          paddingRight: '16px',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" mb={1} mr={3}>
          <Typography variant="h5">On-Target Total:</Typography>
          <Typography variant="h5">{formatCurrency(totalPayout)}</Typography>
        </Stack>
      </CardActions>
    </Paper>
  )
}

const AllocationRow: React.FC<{
  row: VariableAllocationRow
  tableIndex: number
  rowIndex: number
  periodProration: number
  baseSalary: number
  variablePercent: number
  theme: Theme
  slugLabelMap: { [key: string]: string }
  ledgerAccounts: LedgerAccount[] | null
  selectedTargets: ExpandedTarget[] | null
}> = ({
  row,
  tableIndex,
  rowIndex,
  baseSalary,
  variablePercent,
  periodProration,
  theme,
  slugLabelMap,
  ledgerAccounts,
  selectedTargets,
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const rowStyle = row.disabled ? { color: 'gray' } : {}

  // Define marks for min, max, and current value, converting decimal to percentage
  const marks = [
    {
      value: (row.minAllocationPercentage ?? 0) * 100,
      label: `${((row.minAllocationPercentage ?? 0) * 100).toFixed(0)}%`,
    },
    {
      value: (row.allocationPercentage ?? 0) * 100,
      label: `${((row.allocationPercentage ?? 0) * 100).toFixed(0)}%`,
    },
    {
      value: (row.maxAllocationPercentage ?? 1) * 100,
      label: `${((row.maxAllocationPercentage ?? 1) * 100).toFixed(0)}%`,
    },
  ]

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid size={{ xs: 4 }} style={{ display: 'flex', alignItems: 'center' }}>
        {!row.disabled && (
          <IconButton onClick={handleExpandClick} style={{ marginRight: '8px' }}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
        <Typography style={rowStyle}>{row.label}</Typography>
      </Grid>
      <Grid size={{ xs: 2 }}>{!row.disabled && <Typography align="center">{marks[1].label}</Typography>}</Grid>
      <Grid size={{ xs: 2 }}>
        {!row.disabled && <Typography align="center">{(row.maxPayout * 100).toFixed(0)}%</Typography>}
      </Grid>
      <Grid size={{ xs: 2 }} style={{ justifyContent: 'center', display: 'flex' }}>
        {!row.disabled && (
          <AcceleratorSelector
            value={row.achievementMultiplier}
            setValue={(newValue) => {}}
            incrementAmount={0.1}
            reviewOnly={true}
          />
        )}
      </Grid>
      <Grid size={{ xs: 2 }}>
        {!row.disabled && (
          <Typography align="center">
            {formatCurrency(baseSalary * row.allocationPercentage * variablePercent * periodProration)}
          </Typography>
        )}
      </Grid>
      {expanded && !row.disabled && (
        <Grid container size={{ xs: 12 }} justifyContent="center" style={{ marginLeft: '16px', marginRight: '16px' }}>
          <FormulaEditor
            handleFormulaChange={(requirements: string[], options: string[]) => {}}
            selectedTargets={selectedTargets || []}
            currentRequirements={row.formulaRequirements}
            currentOptions={row.formulaOptions}
            organizationLabels={slugLabelMap}
            theme={theme}
            rowType={row.rowType}
            isReviewMode={true}
          />
        </Grid>
      )}
    </Grid>
  )
}

interface PlanAllocationTablesProps {
  plan: ExpandedCompensationPlan
  ledgerAccounts: LedgerAccount[] | null
}

const PlanAllocationsCard: React.FC<PlanAllocationTablesProps> = ({ plan, ledgerAccounts }) => {
  const theme = useTheme()

  const baseSalary = plan.plan.baseSalary
  const variablePercent = plan.plan.variablePercent
  const allocations = plan.plan.variableAllocations
  const slugLabelMap = getSlugLabelMapForPlan(plan)

  const expandedTargets = plan.targets.map((target) => {
    const component = plan.components.find((component) => component.id === target.componentId)
    return {
      target,
      component,
    } as ExpandedTarget
  })

  return (
    <div style={{ width: '100%' }}>
      {allocations.map((table, index) => (
        <AllocationTable
          key={index}
          data={table}
          tableIndex={index}
          baseSalary={baseSalary}
          variablePercent={variablePercent}
          periodProration={plan.plan.periodProration}
          theme={theme}
          slugLabelMap={slugLabelMap}
          ledgerAccounts={ledgerAccounts}
          selectedTargets={expandedTargets}
        />
      ))}
    </div>
  )
}

export default PlanAllocationsCard
