import { Box, LinearProgress, linearProgressClasses, styled, Theme, Typography } from '@mui/material'
import React from 'react'
import { Target } from 'types/components'

interface TargetRowProps {
  target: Target
  findMetricValue: (targetComponentId: string, targetPeriodId: string) => number
  calculateProgress: (targetComponentId: string, targetValue: string, targetPeriodId: string) => number
  theme: Theme
  componentType: string
  hideLabel?: boolean
  targetLabel?: string
}

const TargetRow: React.FC<TargetRowProps> = ({
  target,
  findMetricValue,
  calculateProgress,
  theme,
  componentType,
  hideLabel = false,
  targetLabel,
}) => {
  const rawProgress = calculateProgress(target.componentId || '', target.value || '', target.periodId || '')
  const progress = isNaN(rawProgress) ? 0 : rawProgress
  const metricValue = findMetricValue(target.componentId || '', target.periodId || '')

  const OverTargetLinearProgress = styled(LinearProgress)({
    backgroundColor: theme.palette.info.darker,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.info.main,
    },
  })

  const formatValue = (value: number, componentType: string) => {
    switch (componentType) {
      case 'currency':
        return `$${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      case 'percent':
        return `${(value * 100).toFixed(2)}%`
      case 'integer':
        return value.toFixed(0)
      case 'float':
        return value.toFixed(2)
      default:
        return value.toString()
    }
  }

  const overTarget = progress > 0 ? (100 / progress) * 100 : 0

  console.log(overTarget, ' ', progress)

  const linearProgress =
    progress > 100 ? (
      <OverTargetLinearProgress variant="determinate" value={overTarget} sx={{ height: 12 }} />
    ) : (
      <LinearProgress variant="determinate" value={progress} sx={{ height: 12 }} />
    )

  return (
    <Box sx={{ mb: hideLabel ? 0 : 2 }}>
      {!hideLabel && <Typography variant="body1">{target.label}</Typography>}
      {targetLabel && (
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary, display: 'flex', justifyContent: 'space-between' }}
        >
          {targetLabel}
        </Typography>
      )}
      {linearProgress}
      <Typography
        variant="body2"
        sx={{ color: theme.palette.text.secondary, display: 'flex', justifyContent: 'space-between' }}
      >
        <span>{`Actual: ${formatValue(metricValue, componentType)} / Target: ${formatValue(parseFloat(target.value) || 0, componentType)}`}</span>
        <span>{`${Math.round(progress)}%`}</span>
      </Typography>
    </Box>
  )
}

export default TargetRow
