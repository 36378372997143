export interface SortParams {
  field: string
  order: string
}

export interface PaginationParams {
  page: number
  perPage: number
}

export interface DefaultQueryParams {
  searchTerm: string
  pagination: PaginationParams
  sort: SortParams[]
}

export const generateDefaultQueryParams = (params: DefaultQueryParams): [string, string][] => {
  const queryParams: [string, string][] = []

  if (params.searchTerm) {
    queryParams.push(['searchTerm', params.searchTerm])
  }

  if (params.pagination.page) {
    queryParams.push(['page', `${params.pagination.page}`])
  }

  if (params.pagination.perPage) {
    queryParams.push(['perPage', `${params.pagination.perPage}`])
  }

  if (params.sort.length > 0) {
    const sortFields = params.sort.map((sort) => sort.field).filter(Boolean)
    const sortOrders = params.sort.map((sort) => sort.order).filter(Boolean)

    if (sortFields.length > 0) {
      queryParams.push(['sort', sortFields.join(',')])
      queryParams.push(['order', sortOrders.join(',')])
    }
  }

  return queryParams
}
