// types
import { PalettesProps } from '@ant-design/colors'
import { darken, lighten, SimplePaletteColorOptions } from '@mui/material/styles'
import appColors from 'themes/colors'
import { ThemeMode } from 'types/config'
import { PaletteThemeProps } from 'types/theme'

const getSimpleColorOptions = (color: string, percent: number, contrastText: string): SimplePaletteColorOptions => {
  return {
    lighter: lighten(color, percent * 0.02), // 2x percent lighter
    100: lighten(color, percent * 0.015), // 1.5x percent lighter
    200: lighten(color, percent * 0.01), // 1x percent lighter
    light: lighten(color, percent * 0.005), // 0.5x percent lighter
    400: darken(color, percent * 0.005), // 0.5x percent darker
    main: color, // Base color
    dark: darken(color, percent * 0.01), // 1x percent darker
    700: darken(color, percent * 0.015), // 1.5x percent darker
    darker: darken(color, percent * 0.02), // 2x percent darker
    900: darken(color, percent * 0.025), // 2.5x percent darker
    contrastText: contrastText,
  }
}

const ThemePurcent = (colors: PalettesProps, mode: ThemeMode): PaletteThemeProps => {
  const { grey } = colors

  let primaryColors = getSimpleColorOptions(darken(appColors.turquoise, 0.1), 10, appColors.white)
  let secondaryColors = getSimpleColorOptions(appColors.lightGray, 21, appColors.black)
  let errorColors = getSimpleColorOptions(appColors.crayolaRed, 20, appColors.white)
  let warningColors = getSimpleColorOptions(appColors.pear, 20, appColors.black)
  let infoColors = getSimpleColorOptions(appColors.caribbeanBlue, 20, appColors.white)
  let successColors = getSimpleColorOptions(darken(appColors.kellyGreen, 0.1), 10, appColors.black)

  if (mode === ThemeMode.DARK) {
    primaryColors = getSimpleColorOptions(darken(appColors.turquoise, 0.1), 20, appColors.white)
    secondaryColors = getSimpleColorOptions(darken(appColors.mediumGray, 0.85), 3, appColors.white)
    errorColors = getSimpleColorOptions(appColors.crayolaRed, 20, appColors.white)
    warningColors = getSimpleColorOptions(appColors.pear, 20, appColors.black)
    infoColors = getSimpleColorOptions(darken(appColors.caribbeanBlue, 0.1), 20, appColors.white)
    successColors = getSimpleColorOptions(darken(appColors.kellyGreen, 0.1), 20, appColors.white)
  }

  return {
    primary: primaryColors,
    secondary: secondaryColors,
    error: errorColors,
    warning: warningColors,
    info: infoColors,
    success: successColors,
    grey: {
      0: grey[0],
      50: grey[1],
      100: grey[2],
      200: grey[3],
      300: grey[4],
      400: grey[5],
      500: grey[6],
      600: grey[7],
      700: grey[8],
      800: grey[9],
      900: grey[10],
      A50: grey[15],
      A100: grey[11],
      A200: grey[12],
      A400: grey[13],
      A700: grey[14],
      A800: grey[16],
    },
  }
}

export default ThemePurcent
