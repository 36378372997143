import { Box, LinearProgress, linearProgressClasses, styled, Theme, Typography } from '@mui/material'
import React from 'react'

interface VariableEarnedProgressProps {
  earnedAmount: number
  targetAmount: number
  theme: Theme
  hideLabel?: boolean
}

const VariableEarnedProgress: React.FC<VariableEarnedProgressProps> = ({
  earnedAmount,
  targetAmount,
  theme,
  hideLabel = false,
}) => {
  const calculateProgress = (earned: number, target: number): number => {
    // Handle NaN cases by converting to 0
    const safeEarned = isNaN(earned) ? 0 : earned
    const safeTarget = isNaN(target) ? 0 : target

    // If both are 0, return 0%
    if (safeTarget === 0 && safeEarned === 0) return 0
    // If only target is 0, return 100% to avoid division by zero
    if (safeTarget === 0) return 100

    return (safeEarned / safeTarget) * 100
  }

  const progress = calculateProgress(earnedAmount, targetAmount)

  const OverTargetLinearProgress = styled(LinearProgress)({
    backgroundColor: theme.palette.warning.darker,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.warning.main,
    },
  })

  const DefaultLinearProgress = styled(LinearProgress)({
    backgroundColor: theme.palette.secondary.main,
  })

  const formatCurrency = (value: number) => {
    return `$${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  }

  const overTarget = progress > 0 ? (100 / progress) * 100 : 0

  const linearProgress =
    progress > 100 ? (
      <OverTargetLinearProgress color="warning" variant="determinate" value={overTarget} sx={{ height: 12 }} />
    ) : (
      <DefaultLinearProgress color="warning" variant="determinate" value={progress} sx={{ height: 12 }} />
    )

  return (
    <Box sx={{ mb: hideLabel ? 0 : 2 }}>
      {linearProgress}
      <Typography
        variant="body2"
        sx={{ color: theme.palette.text.secondary, display: 'flex', justifyContent: 'space-between' }}
      >
        <span>{`Actual: ${formatCurrency(earnedAmount)} / Target: ${formatCurrency(targetAmount)}`}</span>
        <span>{`${Math.round(progress)}%`}</span>
      </Typography>
    </Box>
  )
}

export default VariableEarnedProgress
