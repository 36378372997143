import { Box, CircularProgress } from '@mui/material'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { usePeriods } from 'hooks/usePeriods'
import { FC, Suspense, useEffect, useState } from 'react'
import PeriodsTable from '../../../../components/PeriodsTable'

const ClosePeriods: FC = () => {
  const { token } = useAuth()

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ClosePeriodsContent token={token!} />
    </Suspense>
  )
}

const ClosePeriodsContent = ({ token }: { token: string }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { periods: fetchedPeriods, isLoadingPeriods, handleRefetch: refetchPeriods } = usePeriods(token!, null, !!token)

  useEffect(() => {
    setIsLoading(isLoadingPeriods)
  }, [isLoadingPeriods])

  return (
    <div style={{ position: 'relative' }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <SimpleTitle title="Manage Periods" />
            </Box>
          </Box>
          <Box>
            {isLoadingPeriods ? (
              <CircularProgress />
            ) : (
              <PeriodsTable
                periods={fetchedPeriods?.periods || []}
                showStatusAndActions
                token={token!}
                onPeriodsUpdate={refetchPeriods}
              />
            )}
          </Box>
        </div>
      )}
    </div>
  )
}

const LoadingSpinner: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <CircularProgress />
  </Box>
)

export default ClosePeriods
