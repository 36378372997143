import {
  Box,
  CardContent,
  Divider,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@mui/material'
import Card from 'components/@extended/Card'
import Paper from 'components/@extended/Paper'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import VariableCompensationChart from 'components/VariableCompensationChart'
import React, { useEffect, useState } from 'react'
import { ExpandedCompensationPlan } from 'types/comp-plan'
import { Period, createIdLabelMap, createIdToPeriodFromList } from 'types/periods'
import { User } from 'types/user'
import ProgressOverview from './ProgressOverview'
import TargetRow from './TargetRow'
import VariableEarnedProgress from './VariableEarnedProgress'

interface EmployeeDashboardProps {
  user: User
  theme: Theme
  status: number
  plans: ExpandedCompensationPlan[]
}

const EmployeeDashboard: React.FC<EmployeeDashboardProps> = ({ user, theme, status, plans }) => {
  const [selectedPlanId, setSelectedPlanId] = useState<string>('')
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [periodMap, setPeriodMap] = useState<Record<string, Period>>({})

  // Find the currently selected plan
  const currentPlan = plans?.find((p) => p.plan.id === selectedPlanId)

  // Set initial selected plan and periods when plans are loaded
  useEffect(() => {
    if (plans && plans.length > 0) {
      const firstPlan = plans[0]
      setSelectedPlanId(firstPlan.plan.id || '')

      // Get all period IDs from the first plan
      const allPeriodIds = getAllPeriodIds(firstPlan.period)
      setSelectedPeriods(allPeriodIds)

      // Create period map
      const periodMap = createIdToPeriodFromList([firstPlan.period])
      setPeriodMap(periodMap)
    }
  }, [plans])

  // Update selected periods when plan changes
  useEffect(() => {
    if (currentPlan) {
      const allPeriodIds = getAllPeriodIds(currentPlan.period)
      setSelectedPeriods(allPeriodIds)
    }
  }, [selectedPlanId, currentPlan])

  // Helper function to get all period IDs from a period tree
  const getAllPeriodIds = (period: Period): string[] => {
    const ids: string[] = []
    if (period.id) {
      ids.push(period.id)
    }
    if (period.children) {
      period.children.forEach((child) => {
        ids.push(...getAllPeriodIds(child))
      })
    }
    return ids
  }

  useEffect(() => {
    if (plans) {
      console.log('Expanded Plans with Accruals:', plans)
    }
  }, [plans])

  if (!currentPlan || !currentPlan.period || status === 400) {
    return (
      <Grid size={{ xs: 12 }}>
        <Card>
          <CardContent>
            <Typography variant="h5">No valid compensation plan found</Typography>
            <Typography variant="body1">Please contact your manager or HR department for assistance.</Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const findMetricValue = (targetComponentId: string, targetPeriodId: string): number => {
    if (!currentPlan || !currentPlan.metrics) {
      return 0
    }
    const metric = currentPlan.metrics.find((m) => m.componentId === targetComponentId && m.periodId === targetPeriodId)
    return metric && metric.value ? parseFloat(metric.value) : 0
  }

  const calculateProgress = (targetComponentId: string, targetValue: string, targetPeriodId: string): number => {
    const metricValue = findMetricValue(targetComponentId, targetPeriodId)
    const safeTargetValue = parseFloat(targetValue) || 1
    const progress = (metricValue / safeTargetValue) * 100
    return progress
  }

  const getPlanLabel = (plan: ExpandedCompensationPlan): string => {
    // Create label map from the periods array
    const labelMap = createIdLabelMap(plan.period)

    const rootPeriodId = plan.plan.rootPeriodId || ''
    const periodId = plan.plan.periodId || ''

    // If IDs are the same, just return one label
    if (rootPeriodId === periodId) {
      return labelMap[rootPeriodId] || 'Unknown Period'
    }

    // Otherwise, return both labels
    const rootPeriodLabel = labelMap[rootPeriodId] || 'Unknown Root Period'
    const periodLabel = labelMap[periodId] || 'Unknown Period'
    return `${rootPeriodLabel} - ${periodLabel}`
  }

  return (
    <Grid container spacing={2}>
      {/* Main content */}
      <Grid size={{ xs: 9 }}>
        <ProgressOverview
          key="progress-overview"
          plan={currentPlan}
          selectedPeriods={selectedPeriods}
          periodMap={periodMap}
        />

        {/* Add Table Summaries */}
        {currentPlan.plan.variableAllocations
          .filter((table) => {
            // Only show tables that have at least one row matching selected periods
            return table.rows.some((row) => selectedPeriods.includes(row.periodId as string))
          })
          .map((table) => (
            <Paper key={table.id} sx={{ mb: 2, px: 3, py: 3 }}>
              <Grid container spacing={2}>
                {/* Header Row */}
                <Grid size={{ xs: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Component
                  </Typography>
                </Grid>
                <Grid size={{ xs: 5 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {table.label}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 5 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Variable Compensation Earnings
                  </Typography>
                </Grid>

                {/* Divider */}
                <Grid size={{ xs: 12 }}>
                  <Divider />
                </Grid>

                {/* Table Rows */}
                {table.rows.map((row) => {
                  // Find matching ledger or progress entry
                  const ledgerEntry = currentPlan.ledger?.find(
                    (entry) => entry.allocationRowId === row.id && entry.allocationTableId === table.id
                  )

                  const progressEntry = currentPlan.progress?.find(
                    (accrual) => accrual.allocationRowId === row.id && accrual.allocationTableId === table.id
                  ) || { proratedPayoutAmount: 0 }

                  const period = periodMap[row.periodId as string]

                  // Use ledger if period is closed and ledger exists, otherwise use progress
                  const amount =
                    period.isClosed && ledgerEntry != null
                      ? ledgerEntry.balance || 0
                      : progressEntry.proratedPayoutAmount

                  const onTargetPayout =
                    currentPlan.plan.baseSalary * currentPlan.plan.variablePercent * row.allocationPercentage

                  // Handle multiple components per row
                  const componentRows = (row.componentIds || [])
                    .map((componentId) => {
                      const target = currentPlan.targets.find(
                        (t) => t.componentId === componentId && t.periodId === row.periodId
                      )
                      // Skip if target is not found
                      if (!target) return null

                      const progress = calculateProgress(
                        target.componentId || '',
                        target.value || '',
                        target.periodId || ''
                      )
                      const componentType =
                        currentPlan.components.find((c) => c.id === componentId)?.componentType || 'float'
                      const component = currentPlan.components.find((c) => c.id === componentId)

                      return {
                        componentId,
                        label: component?.label || '',
                        target,
                        progress,
                        componentType,
                      }
                    })
                    .filter(Boolean) // Remove null entries

                  // Skip rendering if no valid component rows
                  if (componentRows.length === 0) return null

                  console.log('amount', amount, 'onTargetPayout', onTargetPayout)

                  const rowLableMargin = componentRows.length > 1 ? 4 : 1
                  const variableEarnedProgressMargin = componentRows.length > 1 ? 4.4 : 2

                  return (
                    <React.Fragment key={row.id}>
                      {componentRows
                        .filter((row) => row !== null)
                        .map((componentRow, index) => (
                          <React.Fragment key={`${row.id}-${componentRow.componentId}`}>
                            <Grid size={{ xs: 2 }}>
                              <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: rowLableMargin }}>
                                {index === 0 ? row.label : ''}
                              </Typography>
                            </Grid>
                            <Grid size={{ xs: 5 }}>
                              <Box sx={{ mt: 2, pr: 2 }}>
                                <TargetRow
                                  target={componentRow.target}
                                  findMetricValue={findMetricValue}
                                  calculateProgress={() => componentRow.progress}
                                  theme={theme}
                                  componentType={componentRow.componentType}
                                  hideLabel={true}
                                  targetLabel={
                                    componentRows.length > 1
                                      ? `${periodMap[row.periodId as string]?.label || ''} - ${componentRow.label}`
                                      : undefined
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid size={{ xs: 5 }}>
                              {index === 0 && amount != null && (
                                <Box sx={{ mt: variableEarnedProgressMargin, pr: 2 }}>
                                  <VariableEarnedProgress
                                    earnedAmount={amount}
                                    targetAmount={onTargetPayout}
                                    theme={theme}
                                    hideLabel={true}
                                  />
                                </Box>
                              )}
                            </Grid>
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  )
                })}
              </Grid>
            </Paper>
          ))}
      </Grid>

      {/* Right sidebar */}
      <Grid size={{ xs: 3 }}>
        <Paper sx={{ mb: 2, py: 3, px: 2 }}>
          <FormControl variant="outlined" fullWidth sx={{ mb: 3 }}>
            <InputLabel sx={{ background: theme.palette.background.paper + ' !important' }}>Select Plan</InputLabel>
            <Select
              labelId="plan-select-label"
              id="plan-select"
              variant="outlined"
              value={selectedPlanId}
              label="Select Plan"
              fullWidth
              style={{ borderRadius: '21px' }}
              onChange={(e) => setSelectedPlanId(e.target.value)}
            >
              {plans?.map((plan) => (
                <MenuItem key={plan.plan.id || ''} value={plan.plan.id || ''}>
                  {getPlanLabel(plan)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {currentPlan && (
            <>
              <MultiPeriodSelector
                periods={[currentPlan.period]}
                selectedPeriods={selectedPeriods}
                handleChange={setSelectedPeriods}
                label="Select Periods"
                variant="outlined"
                labelBackgroundColor={theme.palette.background.paper}
              />

              {/* Add Variable Compensation Chart */}
              <Box sx={{ mt: 3 }}>
                {(() => {
                  const approvedAccruals = currentPlan.progress || []

                  // Calculate total earned considering both ledger and accruals
                  const totalEarned = currentPlan.plan.variableAllocations
                    .flatMap((table) => table.rows)
                    .filter((row) => selectedPeriods.includes(row.periodId!))
                    .reduce((acc, row) => {
                      const ledgerEntry = currentPlan.ledger?.find((entry) => entry.allocationRowId === row.id)
                      const progressEntry = approvedAccruals.find((accrual) => accrual.allocationRowId === row.id)
                      const amount =
                        ledgerEntry != null ? ledgerEntry.balance || 0 : progressEntry?.proratedPayoutAmount || 0
                      return acc + amount
                    }, 0)

                  // Calculate on-target total
                  const onTargetTotal = currentPlan.plan.variableAllocations
                    .flatMap((table) => table.rows)
                    .filter(
                      (row) =>
                        selectedPeriods.includes(row.periodId!) && row.rowType === 'CALCULATION_ROW' && !row.disabled
                    )
                    .reduce((acc, row) => {
                      const rowTarget =
                        currentPlan.plan.baseSalary * currentPlan.plan.variablePercent * row.allocationPercentage
                      return acc + rowTarget
                    }, 0)

                  return (
                    <VariableCompensationChart
                      variableEarned={totalEarned}
                      variableTotal={onTargetTotal}
                      currency="USD"
                      currencyDecimals={2}
                      showRemaining={false}
                    />
                  )
                })()}
              </Box>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default EmployeeDashboard
