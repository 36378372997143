import { CloseCircleOutlined } from '@ant-design/icons'
import { Box, Grid2 as Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import Paper from 'components/@extended/Paper'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import useAuth from 'hooks/useAuth'
import { usePeriodOverviews } from 'hooks/useDashboard'
import useLocalStorage from 'hooks/useLocalStorage'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import React, { useEffect, useState, useTransition } from 'react'
import { Period } from 'types/periods'
import ApprovalsTile from './ApprovalsTile'
import OnboardOverviews from './OnboardOverviews'
import TeamEmployeeOverviews from './TeamEmployeeOverviews'
import TeamOverview from './TeamOverview'

const ManagerDashboard: React.FC = () => {
  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const theme = useTheme()
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'dashboard-selected-organizations',
    []
  )
  const [selectedPeriods, setSelectedPeriods] = useLocalStorage<string[]>('dashboard-selected-periods', [])
  const [periods, setPeriods] = useState<Period[]>([])
  const [isPending, startTransition] = useTransition()
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (profile.roles.length > 0 && !isInitialized) {
      const allOrganizationIds = profile.roles.map((role) => role.organization.id)
      if (selectedOrganizations.length === 0) {
        setSelectedOrganizations(allOrganizationIds)
      }
      setIsInitialized(true)
    }
  }, [profile, isInitialized])

  const { periods: fetchedPeriods, isLoadingPeriods } = usePeriods(
    token!,
    selectedOrganizations[0] || '',
    !!token && selectedOrganizations.length > 0
  )

  useEffect(() => {
    if (fetchedPeriods) {
      // Sort periods by end date in descending order
      const sortedPeriods = [...(fetchedPeriods.periods || [])].sort(
        (a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
      )
      setPeriods(sortedPeriods)

      // Select the first period if available (now will be the most recent period)
      if (sortedPeriods.length > 0 && selectedPeriods.length === 0) {
        console.log('Setting selected period to', sortedPeriods[0].id)
        setSelectedPeriods([sortedPeriods[0].id!])
      }
    }
  }, [fetchedPeriods])

  const { periodOverviews, isLoading: isLoadingOverviews } = usePeriodOverviews(
    token!,
    {
      rootPeriodId: selectedPeriods[0] || '',
      organizationIds: selectedOrganizations,
    },
    !!token && selectedPeriods.length > 0 && selectedOrganizations.length > 0 && isInitialized
  )

  const handleOrganizationChange = (newSelectedOrganizations: string[]) => {
    startTransition(() => {
      setSelectedOrganizations(newSelectedOrganizations)
    })
  }

  const handlePeriodChange = (newSelectedPeriods: string[]) => {
    startTransition(() => {
      setSelectedPeriods(newSelectedPeriods)
    })
  }

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid size={9}>
        {!isLoadingOverviews && !isLoadingPeriods && !isPending && periodOverviews ? (
          <>
            <TeamOverview userPeriodOverviews={periodOverviews.userPeriodOverviews} />
            <TeamEmployeeOverviews userPeriodOverviews={periodOverviews.userPeriodOverviews} theme={theme} />
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Grid>
      <Grid size={3}>
        <Paper sx={{ mb: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={1} alignItems="center">
              <Grid size={11}>
                <MultiOrganizationSelector
                  key="organization-selector"
                  userProfile={profile}
                  selectedOrganizationIds={selectedOrganizations}
                  handleChange={handleOrganizationChange}
                  labelBackgroundColor={theme.palette.background.paper}
                />
              </Grid>
              <Grid size={1}>
                <Tooltip title="Reset Organization Filter">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSelectedOrganizations([])
                    }}
                  >
                    <CloseCircleOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={1} alignItems="center">
              <Grid size={11}>
                <MultiPeriodSelector
                  key="period-selector"
                  periods={periods}
                  selectedPeriods={selectedPeriods}
                  handleChange={handlePeriodChange}
                  rootPeriodOnly={true}
                  sortOrder="desc"
                  timeFilter="pastOnly"
                  showLabelAbove={true}
                  variant="outlined"
                  shrinkLabel={true}
                  labelBackgroundColor={theme.palette.background.paper}
                />
              </Grid>
              <Grid size={1}>
                <Tooltip title="Reset Period Filter">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSelectedPeriods([])
                    }}
                  >
                    <CloseCircleOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {periodOverviews && (
          <Paper sx={{ mb: 2 }}>
            <OnboardOverviews
              planApprovalStats={periodOverviews.planApprovalStats}
              targetMetricCounts={periodOverviews.targetMetricCounts}
              approvedComponentCounts={periodOverviews.approvedComponentCounts}
              accrualCounts={periodOverviews.accrualCounts}
              theme={theme}
            />
          </Paper>
        )}

        <Paper sx={{ mb: 2 }}>
          <ApprovalsTile />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ManagerDashboard
