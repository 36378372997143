import { Grid } from '@mui/material'
import React from 'react'

import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'

import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'

import PlanAllocationTables from './PlanAllocationTables'
import PlanComponentSummary from './PlanComponentSummary'
import PlanTargets from './PlanTargets'

const ReviewPlan = () => {
  const { plan, employeeName, compensationTemplate } = useCreatePlanContext()

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <CompensationSummaryCard
            basePlus={plan.basePlus}
            variablePercent={plan.variablePercent || 0}
            baseSalary={plan.baseSalary || 0}
            variableCap={plan.variableCap || -0}
            name={employeeName}
            position={plan.position}
            effectiveDate={plan.effectiveDate}
            period={compensationTemplate?.period}
            periodId={plan.periodId}
            periodProration={plan.periodProration}
          />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <PlanComponentSummary />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <PlanTargets reviewOnly={true} />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <PlanAllocationTables reviewOnly={true} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ReviewPlan
