import { AuditOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import Paper from 'components/@extended/Paper'
import { TextField } from 'components/@extended/TextField'
import AnimateButton from 'components/@extended/AnimateButton'
import ComponentField from 'components/ComponentField'
import SimpleTitle from 'components/SimpleTitle'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { deleteWithToken, putWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useGetComponentById, useGetComponentLabels } from 'hooks/useComponents'
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'
import { useProfile } from 'hooks/useProfile'
import { useSnackbar } from 'notistack'
import { FC, startTransition, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ComponentType, getInputFormatFromComponentType } from 'types/components'
import { ThemeMode } from 'types/config'

const EditComponent: FC = () => {
  const theme = useTheme()
  const { token } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { purcentAppState } = useGlobalState()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { componentId } = useParams<{ componentId: string }>()

  const [selectedOrganization, setSelectedOrganization] = useState<string>('')
  const [label, setLabel] = useState<string>('')
  const [initialLabel, setInitialLabel] = useState<string>('')
  const [componentType, setComponentType] = useState<ComponentType>(ComponentType.COMPONENT_TYPE_INTEGER)
  const [isOrganizationLevel, setIsOrganizationLevel] = useState<boolean>(false)
  const [isTargetEditable, setIsTargetEditable] = useState<boolean>(true)
  const [restrictedVisibility, setRestrictedVisibility] = useState<boolean>(false)
  const [targetMin, setTargetMin] = useState<string>('')
  const [targetMax, setTargetMax] = useState<string>('')
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [isLabelUsed, setIsLabelUsed] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [targetsToDelete, setTargetsToDelete] = useState(0)

  const { coreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    selectedOrganization ? [selectedOrganization] : [],
    token!
  )

  const { labels, isLoading: isLoadingLabels } = useGetComponentLabels(token!, selectedOrganization)
  const {
    details,
    isLoading: isLoadingComponent,
    isError,
    refetch,
  } = useGetComponentById(token!, componentId!, !!componentId)

  useEffect(() => {
    if (details) {
      setSelectedOrganization(details.component.organizationId)
      setLabel(details.component.label)
      setInitialLabel(details.component.label)
      setComponentType(details.component.componentType)
      setIsOrganizationLevel(details.component.isOrganizationLevel)
      setIsTargetEditable(details.component.isTargetEditable)
      setRestrictedVisibility(details.component.restrictedVisibility)
      setTargetMin(details.component.targetMin)
      setTargetMax(details.component.targetMax)
    }
  }, [details])

  const validateForm = useCallback(() => {
    setIsFormValid(selectedOrganization !== '' && label !== '' && targetMin !== '' && targetMax !== '' && !isLabelUsed)
  }, [selectedOrganization, label, targetMin, targetMax, isLabelUsed])

  useEffect(() => {
    validateForm()
  }, [selectedOrganization, label, targetMin, targetMax, isLabelUsed, details, validateForm])

  useEffect(() => {
    if (labels.includes(label) && label !== initialLabel) {
      setIsLabelUsed(true)
    } else {
      setIsLabelUsed(false)
    }
  }, [label, labels, initialLabel])

  useEffect(() => {
    if (componentType === ComponentType.COMPONENT_TYPE_BOOL) {
      setTargetMin('true')
      setTargetMax('true')
    } else {
      setTargetMin('0')
      setTargetMax('0')
    }
  }, [componentType])

  const handleSubmit = () => {
    const componentDTO = {
      rootOrganizationId: purcentAppState?.selectedOrganization?.rootOrganizationId || '',
      organizationId: selectedOrganization,
      ledgerAccountId: null,
      isOrganizationLevel,
      isTargetEditable,
      restrictedVisibility,
      targetMin,
      targetMax,
      label,
      format: getInputFormatFromComponentType(componentType),
      sourceType: 'organization',
      componentType,
    }

    putWithToken(`components/${componentId}`, token!, componentDTO)
      .then(() => {
        enqueueSnackbar('Component updated successfully', { variant: 'success' })
        refetch()
      })
      .catch((error) => {
        const errorMessage = error.message || 'Failed to update component'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
  }

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleConfirmDelete = () => {
    deleteWithToken(`components/${componentId}`, token!)
      .then(() => {
        enqueueSnackbar('Component deleted successfully', { variant: 'success' })
        startTransition(() => {
          navigate('/admin/components/manage')
        })
      })
      .catch((error) => {
        const errorMessage = error.message || 'Failed to delete component'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
    handleCloseDeleteDialog()
  }

  const handleUpdateClick = useCallback(() => {
    let targetsCount = 0
    if (
      details &&
      (componentType !== details.component.componentType ||
        isOrganizationLevel !== details.component.isOrganizationLevel)
    ) {
      targetsCount = details.approvedTargets
    }
    setTargetsToDelete(targetsCount)
    setOpenUpdateDialog(true)
  }, [componentType, isOrganizationLevel, details])

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false)
  }

  const handleConfirmUpdate = () => {
    handleSubmit()
    handleCloseUpdateDialog()
  }

  const handleCancelConfirmation = () => {
    setOpenCancelDialog(false)
    startTransition(() => {
      navigate('/admin/components/manage')
    })
  }

  if (isLoadingComponent || isLoadingProfile || isLoadingCoreSettings || isLoadingLabels) {
    return <CircularProgress />
  }

  if (isError || !details) {
    return <Box>Error loading component</Box>
  }

  const hasTemplatesOrApprovedPlansOrActuals =
    details.templates.length > 0 || details.plans.length > 0 || details.approvedMetrics > 0

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box>
          <SimpleTitle title="Edit Component" />
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="textSecondary">
              Approved Targets: {details.approvedTargets}
            </Typography>
            <Box mx={2}>
              <Typography variant="subtitle2" color="textSecondary">
                |
              </Typography>
            </Box>
            <Typography variant="subtitle2" color="textSecondary">
              Approved Actuals: {details.approvedMetrics}
            </Typography>
          </Box>
        </Box>
        <Tooltip
          title={
            hasTemplatesOrApprovedPlansOrActuals
              ? 'A component can only be deleted if there are no templates, approved plans or actuals associated with it.'
              : 'Delete Component'
          }
        >
          <span>
            <IconButton onClick={handleDeleteClick} disabled={hasTemplatesOrApprovedPlansOrActuals} color="error">
              <DeleteOutlined style={{ fontSize: '1.5rem' }} />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      <Box mb={3}>
        <Box>
          <Grid container spacing={4}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <SingleOrganizationSelector
                key="organization-selector"
                userProfile={profile}
                selectedOrganizationId={selectedOrganization}
                handleChange={(value) => {
                  setSelectedOrganization(value)
                  validateForm()
                }}
                label="Select Organization"
                showLabelAbove={true}
                style={{ marginTop: 3, paddingBottom: 0 }}
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControl fullWidth>
                <InputLabel shrink>Component Label</InputLabel>
                <TextField
                  key="label-field"
                  variant="outlined"
                  fullWidth
                  value={label}
                  onChange={(e) => {
                    setLabel(e.target.value)
                    validateForm()
                  }}
                  error={isLabelUsed}
                  helperText={isLabelUsed ? 'Label is already used' : ''}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <InputLabel shrink>Format</InputLabel>
                <Select
                  value={componentType}
                  onChange={(e) => {
                    const value = e.target.value as ComponentType
                    setComponentType(value)
                  }}
                  fullWidth
                  variant="outlined"
                  disabled={hasTemplatesOrApprovedPlansOrActuals}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                        },
                      },
                    },
                  }}
                  sx={{
                    borderRadius: '21px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '21px',
                    },
                  }}
                >
                  <MenuItem value={ComponentType.COMPONENT_TYPE_INTEGER}>Integer</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_CURRENCY}>Currency</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_PERCENTAGE}>Percentage</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_BOOL}>Boolean</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_FLOAT}>Decimal</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <InputLabel shrink>Type</InputLabel>
                <Select
                  value={isOrganizationLevel}
                  onChange={(e) => setIsOrganizationLevel(e.target.value === 'true')}
                  fullWidth
                  variant="outlined"
                  disabled={hasTemplatesOrApprovedPlansOrActuals}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                        },
                      },
                    },
                  }}
                  sx={{
                    borderRadius: '21px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '21px',
                    },
                  }}
                >
                  <MenuItem value="true">Team</MenuItem>
                  <MenuItem value="false">Individual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <InputLabel shrink>Restricted Visibility</InputLabel>
                <Select
                  value={restrictedVisibility}
                  onChange={(e) => setRestrictedVisibility(e.target.value === 'true')}
                  fullWidth
                  variant="outlined"
                  sx={{
                    borderRadius: '21px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '21px',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <FormControl fullWidth>
                <InputLabel shrink>Is Target Editable</InputLabel>
                <Select
                  value={isTargetEditable}
                  onChange={(e) => setIsTargetEditable(e.target.value === 'true')}
                  fullWidth
                  variant="outlined"
                  sx={{
                    borderRadius: '21px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '21px',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <ComponentField
                componentType={componentType}
                label="Target Min"
                value={targetMin}
                onChange={(value) => {
                  setTargetMin(value)
                  validateForm()
                }}
                name="targetMin"
                disabled={!selectedOrganization || isLoadingCoreSettings}
                currency={coreSettings?.[0]?.currency || 'USD'}
                currencyDecimals={coreSettings?.[0]?.currencyDecimals || 0}
                decimalPlaces={2}
                variant="outlined"
                pill
                shrink
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 4 }}>
              <ComponentField
                componentType={componentType}
                label="Target Max"
                value={targetMax}
                onChange={(value) => {
                  setTargetMax(value)
                  validateForm()
                }}
                name="targetMax"
                disabled={!selectedOrganization || isLoadingCoreSettings}
                currency={coreSettings?.[0]?.currency || 'USD'}
                currencyDecimals={coreSettings?.[0]?.currencyDecimals || 0}
                decimalPlaces={2}
                variant="outlined"
                pill
                shrink
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" alignItems="center" mt={3}>
            {hasTemplatesOrApprovedPlansOrActuals && (
              <Typography variant="body2" color="error" sx={{ flexGrow: 1, mr: 2 }}>
                Component type and organization level can only be changed if there are no templates, approved plans or
                actuals associated with it.
              </Typography>
            )}

            <Box>
              <Stack direction="row" spacing={2}>
                <AnimateButton>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      setOpenCancelDialog(true)
                    }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
                <Tooltip
                  title={
                    !isFormValid
                      ? 'Please fill out all required fields and ensure component is not used in any plans'
                      : ''
                  }
                >
                  <AnimateButton>
                    <Button variant="contained" onClick={handleUpdateClick} disabled={!isFormValid}>
                      Update Component
                    </Button>
                  </AnimateButton>
                </Tooltip>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Templates using this Component */}
      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Templates using this Component
      </Typography>
      <Table component={Paper} size="small" sx={{ p: 3, mb: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell>Label</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.templates.map((template) => (
            <TableRow key={template.id}>
              <TableCell>{template.label}</TableCell>
              <TableCell align="center">
                <Tooltip title="Edit Template">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      startTransition(() => {
                        navigate(`/admin/templates/${template.id}/edit`)
                      })
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Employee Plans using this Component */}
      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Employee Plans using this Component
      </Typography>
      <Table component={Paper} size="small" sx={{ p: 3, mb: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.plans.map((plan) => (
            <TableRow key={plan.id}>
              <TableCell>{`${plan.firstName} ${plan.lastName}`}</TableCell>
              <TableCell>
                {plan.approvalStatus.charAt(0).toUpperCase() + plan.approvalStatus.slice(1).toLowerCase()}
              </TableCell>
              <TableCell align="center">
                <Box display="flex" justifyContent="center">
                  <Tooltip title="View Plan">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        startTransition(() => {
                          navigate(`/employee/${plan.userId}/compplan`)
                        })
                      }}
                    >
                      <EyeOutlined />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Plan">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        startTransition(() => {
                          navigate(`/create-comp-plan?userId=${plan.userId}`)
                        })
                      }}
                    >
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View Approval Batch">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        startTransition(() => {
                          navigate(`/admin/approvals/${plan.approvalBatchId}/edit`)
                        })
                      }}
                    >
                      <AuditOutlined />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Component Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this component?
            {details.approvedTargets > 0 && (
              <Typography color="error" mt={2}>
                Warning: {details.approvedTargets} approved target{details.approvedTargets > 1 ? 's' : ''} will be
                deleted.
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Cancel Confirmation Dialog */}
      <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
        <DialogTitle>{'Confirm cancellation'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to cancel updating this component?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)}>No</Button>
          <Button onClick={handleCancelConfirmation} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Confirmation Dialog */}
      <Dialog
        open={openUpdateDialog}
        onClose={handleCloseUpdateDialog}
        aria-labelledby="update-dialog-title"
        aria-describedby="update-dialog-description"
      >
        <DialogTitle id="update-dialog-title">{'Confirm Component Update'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="update-dialog-description">
            Are you sure you want to update this component?
            {targetsToDelete > 0 && (
              <Typography color="error" mt={2}>
                Warning: {targetsToDelete} approved target{targetsToDelete > 1 ? 's' : ''} will be deleted due to
                changes in Component Type or Organization Level.
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpdate} color="primary" autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default EditComponent
