import { Role } from 'types/role'
import { Feature, User } from 'types/user'

interface OrganizationMetadataType {
  features: Feature[]
}

export interface Organization {
  id: string
  parentId: string | null
  rootOrganizationId: string | null
  name: string
  metadata: OrganizationMetadataType
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export type OrganizationDTO = Pick<Organization, 'id' | 'parentId' | 'rootOrganizationId' | 'name'>

export type CreateOrganizationDTO = Omit<OrganizationDTO, 'id' | 'rootOrganizationId'>

export interface OrganizationSetting {
  settingKey: string
  settingName: string
  organizationId: string
  rootOrganizationId: string
  settingType: 'integer' | 'string' | 'text' | 'json' | 'boolean' | 'float'
  settingValue: string
  metadata?: Record<string, any>
  createdAt: string
  updatedAt: string
}

export type OrganizationSettingDTO = Omit<OrganizationSetting, 'createdAt' | 'updatedAt'>

export interface ExpandedOrganizationUser {
  user: User
  role: Role
}

export interface ExpandedOrganization {
  organization: Organization
  users: ExpandedOrganizationUser[]
}

export interface ExpandedOrganizationHierarchy extends ExpandedOrganization {
  children: ExpandedOrganizationHierarchy[]
}

export function buildOrganizationHierarchy(organizations: ExpandedOrganization[]): ExpandedOrganizationHierarchy {
  const uniqueOrganizations = Array.from(new Map(organizations.map((org) => [org.organization.id, org])).values())
  const orgMap = new Map<string, ExpandedOrganizationHierarchy>()

  uniqueOrganizations.forEach((org) => {
    orgMap.set(org.organization.id, {
      ...org,
      children: [],
    })
  })

  function buildSubtree(orgId: string): ExpandedOrganizationHierarchy {
    const org = orgMap.get(orgId)
    if (!org) {
      throw new Error(`Organization with id ${orgId} not found`)
    }

    uniqueOrganizations.forEach((childOrg) => {
      if (childOrg.organization.parentId === orgId) {
        org.children.push(buildSubtree(childOrg.organization.id))
      }
    })

    // Sort children alphabetically by name
    org.children.sort((a, b) => a.organization.name.localeCompare(b.organization.name))

    return org
  }

  const potentialRoots = uniqueOrganizations.filter(
    (org) =>
      !org.organization.parentId ||
      org.organization.id === org.organization.rootOrganizationId ||
      !uniqueOrganizations.some((parent) => parent.organization.id === org.organization.parentId)
  )

  if (potentialRoots.length === 0) {
    throw new Error('No root organization found')
  }

  if (potentialRoots.length > 1) {
    console.error(
      'Multiple root organizations found:',
      potentialRoots.map((org) => ({
        id: org.organization.id,
        name: org.organization.name,
        parentId: org.organization.parentId,
        rootOrganizationId: org.organization.rootOrganizationId,
      }))
    )
  }

  const root = potentialRoots[0]
  const result = buildSubtree(root.organization.id)
  return result
}

export function findOrganizationById(
  hierarchy: ExpandedOrganizationHierarchy,
  organizationId: string
): ExpandedOrganization | null {
  if (hierarchy.organization.id === organizationId) {
    return {
      organization: hierarchy.organization,
      users: hierarchy.users,
    }
  }

  for (const child of hierarchy.children) {
    const found = findOrganizationById(child, organizationId)
    if (found) {
      return found
    }
  }

  return null
}

export function getOrganizationChildrenCount(
  hierarchy: ExpandedOrganizationHierarchy | null,
  organizationId: string
): number {
  if (!hierarchy) return -1

  if (hierarchy.organization.id === organizationId) {
    return hierarchy.children.length
  }

  for (const child of hierarchy.children) {
    const count = getOrganizationChildrenCount(child, organizationId)
    if (count !== -1) {
      return count
    }
  }

  return -1 // Organization not found
}

export interface OrganizationCoreSetting {
  organizationId: string
  defaultLedgerAccount: string
  currency: string
  currencyDecimals: number
  defaultBasePlus: boolean
  defaultExampleCompensation: number
  defaultVariableCompensation: string
  defaultMaxPayout: string
  defaultTemplateTables: string
  targetApprovalChain: string
  metricApprovalChain: string
  planApprovalChain: string
  accrualApprovalChain: string
}

export interface UpdateOrganizationDTO {
  name?: string
  parentId?: string | null
}
